<script>
export default {
    props: {
        record: {
            type: Object,
            required: true
        },
        args: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            settingScheduledDateAndTime: false
        }
    },

    computed: {
        appointment() {
            return this.$store.getters[
                'appointments_and_schedules/getAppointmentforAppointmentableAndIdAndAppointmentId'
                ](this.args.appointmentableEntityTypeName, this.args.appointmentableEntityId, this.args.appointmentId);
        },
        appointmentEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName']('appointment');
        },
        appointmentableEntityType() {
            if(!this.args.appointmentableEntityTypeName) {
                return null;
            }

            return this.$store.getters['entity_types/getEntityTypeByName'](this.args.appointmentableEntityTypeName);
        },
    },

    methods: {
        async setAppointmentOptionAsScheduledForAppointment() {
            if(!this.appointmentableEntityType){
                window.notify.error('Unable to set appointment option as scheduled', 'Error!');
                return;
            }

            try {
                this.settingScheduledDateAndTime = true;
                await this.$store.dispatch('appointments_and_schedules/setScheduledDateAndTimeFromAppointmentOption', {
                    appointmentableEntityType: this.appointmentableEntityType,
                    appointmentableEntityId: this.args.appointmentableEntityId,
                    appointmentId: this.args.appointmentId,
                    appointmentOption: this.record
                });

                if(window.Bus) {
                    window.Bus.$emit('entity-saved', {
                        entityType: this.appointmentEntityType,
                        entity: this.appointment
                    });
                }
                window.notify.success('Appointment scheduled', 'Saved');
            }catch(err) {
                window.notify.error('Unable to update appointment for the specified date and time', '');
            }
            this.settingScheduledDateAndTime = false;
        }
    }
}
</script>
<template>
    <tr>
        <td>{{ record.appointment_option_at}}</td>
        <td>
            <button class="button" @click="setAppointmentOptionAsScheduledForAppointment" :disabled="settingScheduledDateAndTime">
                <i class="fa fa-spinner fa-spin" v-if="settingScheduledDateAndTime"></i>
                <i class="fa fa-check" v-else></i>
                Select for appointment
            </button>
        </td>
    </tr>
</template>
