import Vue from 'vue';
import {Collection} from "laravel-vue-forms";
import Appointment from 'etap/admin/classes/models/appointment';

export const setSchedulesForUser = (state, {userId, schedules}) => {
    Vue.set(state.schedules, userId, schedules);
}

export const addUserSchedule = (state, {userId, schedule}) => {
    if(!state.schedules[userId]) {
        Vue.set(state.schedules, userId, []);
    }

    state.schedules[userId].push(schedule);
}

export const updateUserSchedule = (state, userSchedule) => {
    if(!state.schedules[userSchedule.user_id]) {
        Vue.set(state.schedules, userSchedule.user_id, []);
    }

    const existingSchedule = state.schedules[userSchedule.user_id].find(eSchedule => {
        return eSchedule.id === userSchedule.id;
    });

    if(!existingSchedule) {
        state.schedules[userSchedule.user_id].push(userSchedule);
        return;
    }

    existingSchedule.assign(userSchedule);
}


export const setAppointmentsForAppointmentableAndId = (state, {appoinmentableEntityType, id, appointments}) => {

    if(!state.appointments[appoinmentableEntityType.name]) {
        Vue.set(state.appointments, appoinmentableEntityType.name, {
            [id]: []
        });
    } else if(!state.appointments[appoinmentableEntityType.name][id]) {
        Vue.set(state.appointments[appoinmentableEntityType.name], id, []);
    }

    if(appointments instanceof Collection) {
        state.appointments[appoinmentableEntityType.name][id] = appointments.getModels();
        return;
    }

    state.appointments[appoinmentableEntityType.name][id] = appointments;
}

export const updateAppointmentForAppointmentable = (state, {appointmentableEntityType, appointmentableEntityId, appointment}) => {


    if(
        !state.appointments[appointmentableEntityType.name] ||
        !state.appointments[appointmentableEntityType.name][appointmentableEntityId]
    ) {
        throw new Error("No appointments found for appointmentable entity type: " + appointmentableEntityType.name + " and id: " + appointmentableEntityId);
    }

    const existingAppointment = state.appointments[appointmentableEntityType.name][appointmentableEntityId].find(eAppointment => {
        return eAppointment.id === appointment.id;
    });

    if(!existingAppointment) {
        state.appointments[appointmentableEntityType.name][appointmentableEntityId].push(
            new Appointment(appointment)
        );
        return;
    }

    existingAppointment.assign(appointment);
}


export const deleteAppointmentForAppointmentable = (state, {appointmentableEntityType, appointmentableEntityId, appointmentId}) => {


    if(
        !state.appointments[appointmentableEntityType.name] ||
        !state.appointments[appointmentableEntityType.name][appointmentableEntityId]
    ) {
        throw new Error("No appointments found for appointmentable entity type: " + appointmentableEntityType.name + " and id: " + appointmentableEntityId);
    }

    const existingAppointmentIndex = state.appointments[appointmentableEntityType.name][appointmentableEntityId].findIndex(eAppointment => {
        return eAppointment.id === appointmentId;
    });

    if(existingAppointmentIndex === -1) {
        return;
    }

    state.appointments[appointmentableEntityType.name][appointmentableEntityId].splice(existingAppointmentIndex, 1);
}
