<template>
    <div class="m-4 mx-auto">
        <div class="w-half vue-form">
            <form-text
                label="URL Search"
                field-name="url-search"
                v-model="url"
            ></form-text>
            <form-text
                label="Parameters Search"
                field-name="parameters-search"
                v-model="params"
            ></form-text>
            <form-select
                field-name="http-method-selector"
                :options="httpMethods"
                v-model="requestMethod"
                label="HTTP Request Method"
                option-label-field="label"
                option-value-field="name"
                ></form-select>
            <form-checkbox
                field-name="filter-by-errors"
                label="Only show error responses?"
                :true-value="1"
                :false-value="0"
                v-model="onlyErrors"
            ></form-checkbox>
        </div>
        <v-grid
            style="width: 85%"
            record-url="/api/dsmc_api/logs"
            :record-url-params="searchParams"
            record-type="dsmc-log-row"
            :headers="headers"
            :queue-refresh.sync="queueRefresh"
            :allow-refresh="true"
            :use-json-api="true"
        ></v-grid>
    </div>
</template>
<script>
import { debounce, throttle } from "lodash";
    export default {

        data() {
            return {
                queueRefresh: false,
                url: '',
                params: '',
                requestMethod: null,
                onlyErrors: 0,
                searchParams: {
                    url: '',
                    params: '',
                    requestMethod: null,
                    onlyErrors: 0
                },
                headers: [
                    'Method',
                    'URL',
                    'Request Parameters',
                    'Response',
                    'Request Made'
                ],
                httpMethods: [
                    {name: 'get', label: 'GET'},
                    {name: 'post', label: 'POST'},
                    {name: 'patch', label: 'PATCH'},
                    {name: 'delete', label: 'DELETE'},
                ]
            }
        },

        watch: {
            url() {
                this.refresh();
            },
            params() {
                this.refresh();
            },
            requestMethod() {
                this.refresh()
            },
            onlyErrors() {
                this.refresh()
            }
        },

        methods: {
            refresh: debounce(function() {
                this.searchParams = {
                    url: this.url,
                    params: this.params,
                    requestMethod: this.requestMethod,
                    onlyErrors: this.onlyErrors
                };
            }, 800)
        }

    }
</script>
<style lang="scss">
.form-control {
    border: 1px solid;

}
</style>
