import {assignOnObject, byString} from "../utilities/utils";

export default {
    data() {
        return {
            conditionValues: {},
            fieldOptions: {}
        };
    },

    methods: {
        generateConditionValues() {
            this.form.formConfig.fields.forEach(field => {
                this.meetsConditions(field);
            });
        },
        meetsConditions(field) {
            let fieldExtra = this.getFormFieldFieldExtra(field);

            if (
                fieldExtra.condition &&
                fieldExtra.condition.valueField &&
                fieldExtra.condition.fieldValue
            ) {
                let fields = this.form.formConfig.fields;
                // if fields is an instance of collection, we need to get the raw array to filter
                if (typeof this.form.formConfig.fields.getModels === 'function') {
                    fields = this.form.formConfig.fields.getModels();
                }

                let conditionFields = fields.filter(f => {
                    return f.value_field === fieldExtra.condition.valueField;
                });

                let conditionField = null;
                if (conditionFields.length > 0) {
                    conditionField = conditionFields[0];
                }

                var conditionFieldFieldExtra = null;
                if (conditionField) {
                    conditionFieldFieldExtra = this.getFormFieldFieldExtra(
                        conditionField
                    );
                } else {
                    // if the valueField from the condition was not found set the view to be false
                    this.$set(this.conditionValues, field.name, false);
                    console.log(
                        "Invalid condition field. [ " +
                        field.name +
                        " ] Is your configuration correct?"
                    );
                    return;
                }

                if (this.getConditionOptions(fieldExtra.condition.valueField)) {
                    // Default option label field to name, as this is the default from FormSelect
                    if (
                        !conditionFieldFieldExtra.options_config
                            .optionLabelField
                    ) {
                        conditionFieldFieldExtra.options_config.optionLabelField =
                            "name";
                    }

                    // Default the option value field to id, as this is the default from FormSelect
                    if (
                        !conditionFieldFieldExtra.options_config
                            .optionValueField
                    ) {
                        conditionFieldFieldExtra.options_config.optionValueField =
                            "id";
                    }

                    let validConditionOptions = this.getConditionOptions(
                        fieldExtra.condition.valueField
                    ).filter(option => {
                        if (Array.isArray(fieldExtra.condition.fieldValue)) {
                            let hasOption = false;
                            fieldExtra.condition.fieldValue.forEach(
                                (conditionFValue) => {
                                    if (
                                        conditionFValue.includes(
                                            option[
                                                conditionFieldFieldExtra.options_config
                                                    .optionLabelField
                                                ]
                                        )
                                    ) {
                                        hasOption = true;
                                    }
                                }
                            );

                            return hasOption;
                        } else {
                            return fieldExtra.condition.fieldValue.includes(
                                option[
                                    conditionFieldFieldExtra.options_config
                                        .optionLabelField
                                    ]
                            );
                        }
                    });

                    // ensure that strings are converted to actual numbers for ID key values
                    var value = this.getFieldValue(
                        this.form.data,
                        conditionField
                    );
                    if (!isNaN(value)) {
                        value = Number(value);
                    }

                    let conditionValueOption = validConditionOptions.find(
                        conditionOption => {
                            return (
                                conditionOption[
                                    conditionFieldFieldExtra.options_config
                                        .optionValueField
                                    ] === value
                            );
                        }
                    );

                    if (validConditionOptions && conditionValueOption) {
                        this.$set(this.conditionValues, field.name, true);
                    } else {
                        this.$set(this.conditionValues, field.name, false);
                    }
                } else {

                    // because EAV form fields are stored as strings, we need to check for boolean like values
                    const currentFieldValue = this.getFieldValue(this.form.data, conditionField);
                    const conditionFieldValueToCheckAgainst = fieldExtra.condition.fieldValue;
                    if (
                        currentFieldValue === 0 ||
                        currentFieldValue === '0' ||
                        currentFieldValue === 1 ||
                        currentFieldValue === '1' ||
                        currentFieldValue === false ||
                        currentFieldValue === 'false' ||
                        currentFieldValue === true ||
                        currentFieldValue === 'true'
                    ) {  // handle 'boolean like' values differently
                        let showConditionField = false;

                        if ( // check falsy values
                            (
                                currentFieldValue === '0' ||
                                currentFieldValue === 0 ||
                                currentFieldValue === 'false' ||
                                currentFieldValue === false
                            ) &&
                            (
                                conditionFieldValueToCheckAgainst === '0' ||
                                conditionFieldValueToCheckAgainst === 0 ||
                                conditionFieldValueToCheckAgainst === 'false' ||
                                conditionFieldValueToCheckAgainst === false
                            )
                        ) {
                            // do a loose check if the above is true
                            showConditionField = currentFieldValue == conditionFieldValueToCheckAgainst;
                        } else if (
                            (
                                currentFieldValue === '1' ||
                                currentFieldValue === 1 ||
                                currentFieldValue === 'true' ||
                                currentFieldValue === true
                            ) &&
                            (
                                conditionFieldValueToCheckAgainst === '1' ||
                                conditionFieldValueToCheckAgainst === 1 ||
                                conditionFieldValueToCheckAgainst === 'true' ||
                                conditionFieldValueToCheckAgainst === true
                            )
                        ) {
                            // do a loose check if the above is true
                            showConditionField = currentFieldValue == conditionFieldValueToCheckAgainst;
                        }


                        this.$set(this.conditionValues, field.name, showConditionField);

                    } else if (currentFieldValue === fieldExtra.condition.fieldValue) {
                        this.$set(this.conditionValues, field.name, true);
                    } else {
                        this.$set(this.conditionValues, field.name, false);
                    }

                }
            } else {
                this.$set(this.conditionValues, field.name, true);
            }
        },
        getFormFieldFieldExtra(field) {
            var fieldExtra = field.field_extra;
            if (!fieldExtra) {
                fieldExtra = {};
            }
            return fieldExtra;
        },
        updateValueAndConditionals(newVal, field) {
            this.updateFormValue(field, newVal);
            this.generateConditionValues();
        },
        getConditionOptions(valueField) {
            return byString(this.form.formFieldOptions, valueField);
        },
        updateOptionsForField(newOptions, field) {
            assignOnObject(
                this.form.formFieldOptions,
                field.value_field,
                newOptions
            );
            this.generateConditionValues();
        }
    }
};
