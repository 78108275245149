<script>
import { mapActions } from "vuex";
import {FormErrors} from "laravel-vue-forms";

export default {
    props: {
        authUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            isSaving: false,
            formErrors: new FormErrors(),
        }
    },

    async created() {
        try {
            await this.getEntityTypeForName('appointment');

            if(!this.appointmentExportForm && this.appointmentEntityType?.entity_configuration?.export_form) {
                await this.getFormConfigurationsByName(
                    [this.appointmentEntityType.entity_configuration.export_form]
                );
            }
        } catch(err) {
            console.log(err);
            window.notify.apiError(err);
        }
    },

    computed: {
        appointmentEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName']('appointment');
        },
        appointmentExportForm() {
            if(!this.appointmentEntityType || !this.appointmentEntityType?.entity_configuration?.export_form) {
                return null;
            }

            return this.$store.getters[
                "form_configurations/getFormConfigurationByName"
            ](this.appointmentEntityType.entity_configuration.export_form);
        },
    },
    methods: {
        ...mapActions("entity_types", ["getEntityTypeForName"]),
        ...mapActions("appointments_and_schedules", ["exportAppointmentsForUser"]),
        ...mapActions("form_configurations", ["getFormConfigurationsByName"]),
        async exportAppointments(formData) {
            this.isSaving = true;
            try {
                const response = await this.exportAppointmentsForUser({
                    userId: this.authUserId,
                    startDate: formData.start_date,
                    endDate: formData.end_date,
                    appointmentTypeId: formData.appointment_type_id,
                });

                if(!response.data.meta.created) {
                    window.notify.error(response.data.meta.message);
                } else {
                    window.notify.success(response.data.meta.message);
                }

            } catch (error) {
                if(error.response && error.response.status === 422) {
                    this.formErrors.report(error);
                } else {
                    window.notify.error('There was an error exporting the appointments.');
                }
            } finally {
                this.isSaving = false;
            }
        }
    }
}
</script>
<template>
    <div v-if="appointmentExportForm" class="panel mb-4 flex">
        <div class="panel-body">
            <vue-form
                :form-config="appointmentExportForm"
                :form-data="{}"
                :form-errors="formErrors"
                :actions="[{name: 'submit', action: 'submitForm', label: 'Export'}]"
                :pass-thru="true"
                :is-saving="isSaving"
                saving-text="Exporting..."
                @updated="exportAppointments"
            ></vue-form>
        </div>
    </div>
</template>
