<script>
import { faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import MultiSelect from "vue-multiselect";
import {debounce} from "lodash";

export default {
    props: {
        projectId: {
            required: true,
            type: Number,
        }
    },

    components: {
        MultiSelect
    },

    data() {
        return {
            icons: {
                plus: faPlus,
                filter: faFilter,
            },
            initializing: false,
            loadingTasks: false,
            showFilters: false,
            filters: {
                q: null,
                task_type_id: [],
                task_status_id: [],
                sort: 'date_due',
            },
            sorts: [{
                name: 'Due Date',
                id: 'date_due',
            },{
                name: 'Most Recently Created',
                id: 'created_at:desc',
            },{
                name: 'Most Recently Updated',
                id: 'updated_at:desc',
            }],
            filterWatcher: null
        }
    },

    async created() {
        if(!this.tasksEnabled) {
            return;
        }

        try {
            this.initializing = true;

            // get the configured lookup models for tasks but wait to load the tasks themselves
            // until we've initialized the tasks layout view
            await Promise.all([
                this.$store.dispatch('lookup/getLookupForModel', this.taskFeature.options?.task_types),
                this.$store.dispatch('lookup/getLookupForModel', this.taskFeature.options?.task_statuses),
                this.$store.dispatch('lookup/getLookupForModel', this.taskFeature.options?.task_states),
                this.$store.dispatch('form_configurations/getFormConfigurationsByName', [this.taskFeature.options?.tasks_form_configuration]),
            ]);

            if(!this.projectEntityType) {
                await this.$store.dispatch('entity_types/getEntityTypeForName', 'project');
            }
            if(!this.taskEntityType) {
                await this.$store.dispatch('entity_types/getEntityTypeForName', 'task');
            }
            this.filters.task_type_id = this.taskTypes.map(t => t.id);
            this.filters.task_status_id = this.taskStatuses.map(s => s.id);

            await this.getTasksForProject();
            this.setupFilterWatcher();

        }catch(err) {
            window.notify.apiError(err);
        }finally {
            this.initializing = false;
        }

    },

    computed: {
        taskFeature() {
            return window.MAT.features.find(f => f.name === 'tasks');
        },
        tasksEnabled() {
            return this.taskFeature?.enabled;
        },
        tasksForProject() {
            return this.$store.getters['tasks/tasksForProjectId'](this.projectId);
        },
        taskFormConfiguration() {
            if(
                !this.taskFeature?.options?.tasks_form_configuration
            ) {
                return null;
            }


            return this.$store.getters['form_configurations/getFormConfigurationByName']
                (this.taskFeature.options.tasks_form_configuration);
        },
        hasNewTask() {
            return this.tasksForProject.some(t => !t.id);
        },
        projectEntityType(){
            return this.$store.getters['entity_types/getEntityTypeByName']('project');
        },
        taskEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName']('task');
        },
        taskStatuses() {
            const statusesModel = this.taskFeature.options?.task_statuses;
            if(!statusesModel) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](statusesModel);
        },
        taskTypes() {
            const taskTypesModels = this.taskFeature.options?.task_types;
            if(!taskTypesModels) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](taskTypesModels);
        },
    },

    methods: {
        setupFilterWatcher() {
            this.filterWatcher = this.$watch('filters', debounce(() => {
                this.getTasksForProject();
            }, 1500), {deep: true});
        },
        async getTasksForProject() {
            try {
                this.loadingTasks = true;
                this.filterWatcher?.();
                await this.$store.dispatch('tasks/getTasksForProject', {
                    projectId: this.projectId,
                    filters: this.filters,
                });

                this.setupFilterWatcher();
            }catch(err) {
                window.notify.apiError(err);
            }finally {
                this.loadingTasks = false;
            }
        },
        createTaskForProject() {
            this.$store.commit('tasks/createTaskForProject', this.projectId);
        }
    }
}
</script>
<template>
    <div class="mx-4">

        <div class="my-4 py-4 border-b flex justify-between items-center">
            <h2>Project Tasks</h2>
            <button class="button" v-if="tasksEnabled" :disabled="hasNewTask" @click="createTaskForProject">
                <font-awesome-icon :icon="icons.plus"/>&nbsp;
                Add Task
            </button>
        </div>

        <div v-if="!tasksEnabled" class="alert alert-warning">
            <p>
                Tasks are not enabled for this project. Please contact your administrator to enable tasks.
            </p>
        </div>
        <div v-else>
            <button class="button-secondary-small" @click="showFilters = !showFilters">
                <font-awesome-icon
                    :icon="icons.filter"
                ></font-awesome-icon>
                &nbsp;
                <span v-if="showFilters">Hide Filters</span>
                <span v-else>Show Filters</span>
            </button>
            <div class="flex mt-2">
                <div v-show="showFilters" class="w-full">
                    <div class="flex justify-between">
                        <form-multi-select
                            :options="taskTypes"
                            field-name="task-task-types-filter"
                            label="Task Types"
                            v-model="filters.task_type_id"
                            class="w-1/2 mx-1"
                        />
                        <form-text
                            field-name="task-query-filter"
                            label="Search"
                            :show-label="false"
                            v-model="filters.q"
                            class="w-1/2 mx-1"
                        ></form-text>
                    </div>
                    <div class="flex justify-between items-center">
                        <form-multi-select
                            :options="taskStatuses"
                            field-name="task-task-statuses-filter"
                            label="Statuses"
                            v-model="filters.task_status_id"
                            class="w-1/2 mx-1"
                        />
                        <form-select
                            field-name="task-sorts"
                            label="Sort By"
                            :options="sorts"
                            v-model="filters.sort"
                            class="w-1/2 mx-1"
                        />
                    </div>
                </div>
            </div>
            <v-grid
                v-if="!initializing"
                :use-json-api="true"
                :allow-refresh="true"
                :refresh-records="getTasksForProject"
                :external-loading="loadingTasks"
                grid-type="divRow"
                :grid-args="{noRecordsText: 'No tasks found for project', projectId: projectId}"
                :data="tasksForProject"
                record-type="project-task-row"
            />
            <div class="text-center flex justify-center mb-4" v-else>
                <div class="loading-bar w-1/3 text-white rounded">
                    <span class="ml-4">Loading ...</span>
                </div>
                &nbsp;
            </div>
        </div>

    </div>
</template>
