<script>

export default {
    props: {
        projectId: {
            required: true,
            type: Number
        },
        phase: {
            required: true,
            type: Object
        },
        formConfiguration: {
            required: true,
            type: Object
        },
        formData: {
            required: true,
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autoSave: {
            type: Boolean,
            default: true,
        },
        additionalBaseRequestIncludes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        additionalDataIncludes: {
            type: Object ,
            default: () => {
                return {};
            },
        },
        formActions: {
            type: Array,
            default: () => {
                return [{
                    name: 'save',
                    label: 'Save',
                    action: 'submitForm',
                }];
            },
        }
    },

    methods: {
        updated() {
            window.notify.success('Project Updated!');
            if (window.Bus) {
                setTimeout(() => {
                    window.Bus.$emit('updateChecklist');
                }, 2000);
            }
        }
    }
}

</script>
<template>
    <div>
        <vue-form
            :form-config="formConfiguration"
            :form-data="Object.assign(formData, {id: projectId})"
            :disabled="disabled"
            :form-submit-url="'/api/projects/' + projectId + '/phase/' + phase.id + ''"
            :auto-save="autoSave"
            :auto-save-timeout="2000"
            :actions="formActions"
            :additional-base-request-includes="additionalBaseRequestIncludes"
            :additional-data-includes="additionalDataIncludes"
            @updated="updated"
        />
    </div>
</template>
