
import Model from "./model";


class Note extends Model {

    defaults() {
        return {
            id: null,
            notable_id: null,
            notable_type: null,
            created_by_id: null,
            external: false,
            note: null,
        }
    }

    casts() {
        return {
            created_at: this.parseDate,
            updated_at: this.parseDate,
            deleted_at: this.parseDate
        }
    }

    relationships() {
        return {

        }
    }



}

export default Note;
