import Parser from "etap/admin/classes/jsonapi_parser";
import {getApiUrlForAppointmentableAndId, getAppointmentforAppointmentableAndIdAndAppointmentId} from "./getters";
import Collection from "../../admin/classes/collection";

export const createScheduleForUser = async (
  context,
  { userId, scheduleName }
) => {
  const response = await axios.post("/api/users/" + userId + "/schedules", {
    name: scheduleName,
    user_id: userId,
  });

  const userSchedule = Parser.parseJSONAPIResponse(response.data);

  context.commit("addUserSchedule", {
    userId: userId,
    schedule: userSchedule,
  });

  return userSchedule;
};

export const exportAppointmentsForUser = async (
  context,
  { userId, startDate, endDate, appointmentTypeId }
) => {
  const response = await axios.get("/api/users/" + userId + "/appointments/export", {
    params: {
        start_date: startDate,
        end_date: endDate,
        appointment_type_id: appointmentTypeId
    }
  });

  return response;
};

export const getAppointmentsForAppointmentableAndId = async (
  context,
  { appoinmentableEntityType, id, include }
) => {
  const apiUrl = context.getters.getApiUrlForAppointmentableAndId(
    appoinmentableEntityType,
    id
  );

  if (!include) {
    include = [];
  }

  const appointmentsResponse = await axios.get(apiUrl, {
    params: {
      include,
    },
  });

  let appointments = Parser.parseJSONAPIResponse(appointmentsResponse.data);

  if (appointments instanceof Collection) {
    appointments = appointments.getModels();
  }

  context.commit("setAppointmentsForAppointmentableAndId", {
    appoinmentableEntityType: appoinmentableEntityType,
    id: id,
    appointments,
  });

  return appointments;
};

export const deleteAppointmentForAppointmentable = async (
  context,
  { appointmentableEntityType, appointmentableEntityId, appointmentId }
) => {
  const apiUrl =
    context.getters.getApiUrlForAppointmentableAndId(
      appointmentableEntityType,
      appointmentableEntityId
    ) +
    "/" +
    appointmentId;
  await axios.delete(apiUrl);

  context.commit("deleteAppointmentForAppointmentable", {
    appointmentableEntityType: appointmentableEntityType,
    appointmentableEntityId: appointmentableEntityId,
    appointmentId: appointmentId,
  });
};

export const sendAppointmentInvitationForAppointmentable = async (
  context,
  {
    appointmentableEntityType,
    appointmentableEntityId,
    appointmentId,
    appointmentType,
    notificationName,
  }
) => {
  const apiUrl =
    context.getters.getApiUrlForAppointmentableAndId(
      appointmentableEntityType,
      appointmentableEntityId
    ) +
    "/" +
    appointmentId;
  await axios.post(apiUrl + "/send_invitation", {
    appointment_type: appointmentType,
    notification_name: notificationName,
  });
};

export const setScheduledDateAndTimeFromAppointmentOption = async(
    context,
    {
        appointmentableEntityType,
        appointmentableEntityId,
        appointmentId,
        appointmentOption
    }
) => {

    const appointment = context.getters.getAppointmentforAppointmentableAndIdAndAppointmentId(
        appointmentableEntityType.name,
        appointmentableEntityId,
        appointmentId
    );

    const apiUrl = context.getters.getApiUrlForAppointmentableAndId(
            appointmentableEntityType,
            appointmentableEntityId
        ) +
        "/" +
        appointmentId;

    let appointmentToUpdate = JSON.parse(JSON.stringify(appointment.toJSON()));
    appointmentToUpdate.scheduled_appointment_at = appointmentOption.appointment_option_at;

    const updatedAppointmentResponse = await axios.patch(apiUrl, {
        ...appointmentToUpdate
    });

    const updatedAppointment = Parser.parseJSONAPIResponse(updatedAppointmentResponse.data);

    context.commit('updateAppointmentForAppointmentable', {
        appointmentableEntityType,
        appointmentableEntityId,
        appointment: updatedAppointment
    });
}
