import axios from 'axios';
import Parser from 'etap/admin/classes/jsonapi_parser';

export default {
    namespaced: true,

    state: {
        notes: [],
    },

    mutations: {
        upsertNotesCollection(state, notesCollection) {
            notesCollection.getModels().forEach(note => {
               const existingNoteIndex = state.notes.findIndex(existingNote => existingNote.id === note.id);

                if(existingNoteIndex >= 0) {
                    state.notes.splice(existingNoteIndex, 1, note);
                } else {
                    state.notes.push(note);
                }
            })
        },
        upsertNote(state, note) {
            const existingNoteIndex = state.notes.findIndex(existingNote => existingNote.id === note.id);

            if(existingNoteIndex >= 0) {
                state.notes.splice(existingNoteIndex, 1, note);
            } else {
                state.notes.push(note);
            }
        },
        removeNote(state, note) {
            const existingNoteIndex = state.notes.findIndex(existingNote => {
                return existingNote.id === note.id
            });

            if(existingNoteIndex >= 0) {
                state.notes.splice(existingNoteIndex, 1);
            }
        }
    },

    actions: {
        async getNotesForEntity({ commit }, baseUrl) {
            const searchParams = {
                include: ["created_by"],
            };

            const notesResponse = await axios.get(baseUrl + "/notes", {
                params: searchParams,
                headers: {
                    Accept: "application/vnd.api+json",
                }
            });

            const notes = Parser.parseJSONAPIResponse(notesResponse.data);
            commit("upsertNotesCollection", notes);
        },
        async deleteNoteForEntity({commit }, {baseUrl, note}) {
            const response = await axios.delete(
                baseUrl + "/notes/" + note.id
            );

            commit('removeNote', note);
        }
    },

    getters: {
        getNotesForNotable: (state) => ({notable,notableEntityType}) => {
            let notableType;
            switch(notableEntityType.type) {
                case 'model':
                    notableType = notableEntityType.built_in_type;
                    break;
                case 'custom':
                    notableType = window.MAT.model_map.entity;
                    break;
                default:
                    throw new Error("Unknown notable entity type: " + notableEntityType);
            }

            return state.notes.filter(note => {
                return note.notable_type === notableType && note.notable_id === notable.id;
            })
        }


    }
}
