<template>
    <div class="submit-phase-for-completion-button">
        <ul v-if="messages.length > 0">
            <li v-for="message in messages" v-text="message"></li>
        </ul>
        <bypass-project-submission-validation
            :permissions="bypassPermissions"
            @bypass-validation-option-updated="canBypassProjectSubmission"
        ></bypass-project-submission-validation>
        <button
            class="inputbutton1"
            :disabled="!canSubmit || loadingSubmitStatus || projectConfigurationUpdateIsRunning"
            @click.prevent="showSubmitModal"
        >
            <span v-if="loadingSubmitStatus || projectConfigurationUpdateIsRunning"><i class="fa fa-spinner fa-spin"></i></span>
            <span>{{ label }}</span>
        </button>
        <div v-if="inCompleteHelpText" class="text-sm mt-2">
            {{ inCompleteHelpText }}
        </div>
        <modal
            v-if="showUpdateConfigurationWarningModal"
            modal-width="500px"
            @close="showUpdateConfigurationWarningModal = false"
        >
            <div slot="body">
                <div class="text-left" v-html="needsConfigurationUpdateConfig.configurationWarningMessage"></div>
                <div class="text-sm mt-2">
                    <button class="inputbutton1" @click="showConfirmationModal">
                        {{ needsConfigurationUpdateConfig.configurationSubmitButtonText }}
                    </button>
                    <button class="inputbutton1" @click="showUpdateConfirmationModal">
                        {{ needsConfigurationUpdateConfig.configurationUpdateButtonText }}
                    </button>
                    <button class="inputbutton1" @click="showUpdateConfigurationWarningModal = false">Cancel</button>
                </div>
            </div>
        </modal>
        <modal
            v-if="showConfirmModal"
            :is-confirm="true"
            modal-width="500px"
            @close="showConfirmModal = false"
            @confirmed="runSubmission"
            :confirm-text="confirmText"
            :cancel-text="cancelText"
        >

            <h4 slot="header">
                {{ modalHeader }}
            </h4>

            <div slot="body" v-html="modalBody"></div>
        </modal>
        <modal
            v-if="showConfirmUpdateModal"
            modal-width="500px"
            @close="showConfirmUpdateModal = false"
        >
            <div slot="body">
                <div class="text-left" v-html="needsConfigurationUpdateConfig.configurationUpdateConfirmText"></div>
                <div class="text-sm mt-2">
                    <button
                        class="inputbutton1"
                        @click="executeConfigurationUpdateForProject"
                        :disabled="executingConfigurationUpdate"
                    >
                        <span v-if="executingConfigurationUpdate"><i class="fa fa-spinner fa-spin"></i></span>
                        <span>{{ needsConfigurationUpdateConfig.configurationUpdateButtonText }}</span>
                    </button>
                    <button
                        class="inputbutton1"
                        @click="showConfirmUpdateModal = false"
                        :disabled="executingConfigurationUpdate"
                    >
                        <span v-if="executingConfigurationUpdate"><i class="fa fa-spinner fa-spin"></i></span>
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {

    props: {
        project: {
            required: true,
            type: Object,
        },
        phase: {
            required: true,
            type: Object
        },
        label: {
            required: true,
            type: String
        },
        confirmText: {
            type: String,
            default: 'Submit'
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        modalHeader: {
            required: true,
            type: String,
        },
        modalBody: {
            required: true,
            type: String
        },
        inCompleteHelpText: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showAllMessages: {
            type: Boolean,
            default: false,
        },
        showMessageKeyword: {
            type: String,
            default: null,
        },
        bypassPermissions: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            canSubmit: false,
            serverErrorMessages: [],
            loadingSubmitStatus: false,
            executingConfigurationUpdate: false,
            showConfirmModal: false,
            showUpdateConfigurationWarningModal: false,
            showConfirmUpdateModal: false,
            bypassProjectSubmissionValidation: false,
        }
    },

    computed: {
        needsConfigurationUpdateConfig() {
            return this.$store.getters['projects/getNeedsConfigurationUpdateConfigForProjectId'](this.project.id);
        },
        needsUpdate() {
            if (!this.needsConfigurationUpdateConfig) {
                return false;
            }

            return this.needsConfigurationUpdateConfig.needsUpdate
        },
        projectConfigurationUpdateIsRunning() {
            return this.$store.state.projects.projectConfigurationUpdateIsRunning;
        },
        messages() {
            var messages = [];
            if (this.showAllMessages) {
                return this.serverErrorMessages;
            }

            if (this.showMessageKeyword !== null) {
                this.serverErrorMessages.forEach(message => {
                    if (typeof message !== "string") {
                        return;
                    }

                    var keywords = this.showMessageKeyword.split(',')
                    keywords.forEach(keyword => {
                        if (message.includes(keyword)) {
                            messages.push(message);
                        }
                    })
                })
            }

            return messages;
        }
    },

    async created() {
        if (this.disabled) {
            return;
        }
        this.$store.commit('projects/setProjectForConfigurationStatusChecking', this.project);
        await this.$store.dispatch('projects/checkForConfigurationUpdate');
        if (this.projectConfigurationUpdateIsRunning) {
            this.$watch('projectConfigurationUpdateIsRunning', async(newRunning, oldRunning) => {
                if(oldRunning === true && newRunning === false) {
                    await this.checkIfPhaseIsComplete();
                }
            });

            return; // don't check if we got a status, as the updating code will set that up
        }
        await this.checkIfPhaseIsComplete();
    },

    methods: {
        ...mapActions('checklists', [
            'getChecklistItems'
        ]),
        async validateSubmit() {
            this.loadingSubmitStatus = true;
            const responseData = await this.$store.dispatch('projects/getProjectCompleteForPhase', {
                projectId: this.project.id,
                phaseId: this.phase.id
            });

            if (responseData.is_complete || this.bypassProjectSubmission) {
                this.canSubmit = true;
                this.serverErrorMessages = [];
            } else {
                this.canSubmit = false;
                this.serverErrorMessages = responseData.messages;
            }

            this.loadingSubmitStatus = false;
        },
        runSubmission() {

            if (this.canSubmit) {
                this.loadingSubmitStatus = true;
                this.showConfirmModal = false;

                let requestData = {};
                if(this.bypassProjectSubmissionValidation) {
                    requestData.bypassProjectSubmissionValidation = true;
                }

                axios.post(
                    '/api/projects/' + this.project.id + '/phase/' + this.phase.id + '/complete',
                    requestData
                ).then(response => {
                    if (response.data.success === true) {
                        window.notify.message('Successfully completed project phase', 'success');
                        this.canSubmit = false;
                        setTimeout(function () {
                            window.location.reload();
                        }, 2500)
                    } else {
                        window.notify.message(response.data.message, 'error');
                        // update checklist
                        this.getChecklistItems({model: this.project, modelType: 'project'}).then(response => {
                            if (window.Bus) {
                                window.Bus.$emit('checklistUpdated');
                            }
                        });
                    }

                    this.loadingSubmitStatus = false;
                }).catch(error => {
                    window.notify.apiError(error);
                    this.loadingSubmitStatus = false;
                });
            }
        },
        showSubmitModal() {
            if (!this.needsUpdate) {
                this.showConfirmModal = true
            }

            this.showUpdateConfigurationWarningModal = true;
        },
        showConfirmationModal() {
            this.showUpdateConfigurationWarningModal = false;
            this.showConfirmModal = true;
        },
        showUpdateConfirmationModal() {
            this.showUpdateConfigurationWarningModal = false;
            this.showConfirmUpdateModal = true;
        },
        async executeConfigurationUpdateForProject() {
            this.executingConfigurationUpdate = true;

            try {
                const responseData = await this.$store.dispatch('projects/updateProjectConfiguration', {
                    projectId: this.project.id
                });
                this.showConfirmUpdateModal = false;
                this.$store.commit('projects/updateProjectConfigurationUpdateStatus', responseData.jobStatus);
                this.$store.commit('projects/projectConfigurationUpdateIsRunning', true)
                await this.$store.dispatch('projects/checkForConfigurationUpdate');

            } catch (err) {
                console.log(err);
            }

            this.executingConfigurationUpdate = false;
        },
        async checkIfPhaseIsComplete() {
            await this.validateSubmit();

            if (window.Bus) {
                window.Bus.$on('checklistUpdated', () => {
                    this.validateSubmit();
                });

            }
        },
        canBypassProjectSubmission(bypassProjectSubmissionValue) {
            if(bypassProjectSubmissionValue) {
                this.bypassProjectSubmissionValidation = true;
                this.canSubmit = true;
            } else {
                this.bypassProjectSubmissionValidation = false;
                this.validateSubmit();
            }
        }
    }

}
</script>
<style lang="scss">
.submit-phase-for-completion-button {
    margin: 20px;
}
</style>
