<template>
    <div class="room" :data-roomid="room.id">
        <div class="flex justify-between items-center roomhead">
            <div class="p-1 flex">
                <button
                    :id="'room-' + room.id + '-expandcollapsebutton'"
                    class="inputbutton1 roomcontrolbutton"
                    :title=" roomExpanded ? 'Close' : 'Open'"
                    @click.stop="toggleRoomExpanded">
                    <span class="fa fa-chevron-right" :class="{ 'fa-chevron-down': roomExpanded }"></span>
                </button>
                <button
                    :disabled="!update_room_hours"
                    :id="'room-' + room.id + '-deletebutton'"
                    class="inputbutton1 roomcontrolbutton"
                    :title=" room.expanded ? 'Close' : 'Open'"
                    @click.stop="showDeleteRoomModal">
                    <span class="fa fa-times" ></span>
                </button>
            </div>
            <div class="lg:flex justify-between items-center w-full text-right">
                <div v-if="!roomExpanded" class="lg:hidden">
                    Measures: {{room.measure_count}}
                </div>
                <form-float-label-text
                    :field-name="room.id + '-room-name'"
                    label="Room Name"
                    :value="roomName"
                    @input="updateRoomName"
                    :disabled="!update_room_hours ? 1: 0"
                />

                <div v-if="!roomExpanded" class="hidden lg:block">
                    Measures: {{room.measure_count}}
                </div>
                <schedule-builder
                    class="w-1/3"
                    label="Operating hours"
                    :disabled="!update_room_hours ? 1 : 0"
                    :value="room.room_operating_hours"
                    :field-name="room.id + '-room_operating_hours'"
                    :show-label="false"
                    :project-id="room.project_id"
                    @input="updateRoomHours"
                    :as-float-label-text="true"
                ></schedule-builder>
            </div>
        </div>
        <div v-show="roomExpanded" class="identifiedmeasurelist">
            <project-measure
                v-show="!loadingRoomMeasures"
                v-for="projectMeasure in projectMeasuresForRoom"
                :key="projectMeasure.id"
                :project-measure="projectMeasure"
            ></project-measure>
            <div v-if="loadingRoomMeasures" class="text-center flex justify-center mb-4 relative">&nbsp;
                <div class="loading-bar w-3/4 text-white p-12 rounded">
                    <span class="ml-4">Loading ...</span>&nbsp;
                </div>
            </div>
            <div v-if="add_measures"
                :id="'room-' + room.id + '-emptymeasureslot'"
                class="measureslot emptymeasureslot">
                Drag a measure here to add.
            </div>
        </div>
        <modal
            v-if="showRoomDeleteModal"
            :isConfirm="true"
            confirmText="Confirm Delete"
            @close="showRoomDeleteModal = false"
            @confirmed="deleteSelectedRoom">

            <span slot="header">Delete Room</span>
            <div slot="body" id="measure-delete-dialog-text">Are you sure you want to delete this room?</div>
        </modal>
    </div>
</template>
<script>
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
    import {mapActions, mapGetters, mapState} from 'vuex';
    import { debounce } from 'lodash';

    export default {
        props: ['room'],

        mixins: [MeasurePickerUtils],

        data() {
            return {
                showRoomDeleteModal: false,
                roomName: this.room.room_name,
                roomHours: this.room.operating_hours,
                loadingRoomMeasures: false,
            }
        },

        created() {
            if(this.roomExpanded === null) {
                let defaultExpansion = this.$store.state.mp_layout.measure_pickers[this.mpid].calculationConfiguration['room_list_starts_expanded'] ||
                    false;
                this.$store.commit('mp_layout/setRoomExpanded', {mpid: this.mpid, room: this.room, expanded: defaultExpansion});
            }
        },

        computed: {
            ...mapState('mp_layout', [
                'roomHoursDisabled',
                'editOnly'
            ]),
            project() {
                return this.$store.getters['mp_layout/getProject'](this.mpid);
            },
            currentScenario() {
                return this.$store.getters['mp_scenarios/getCurrentScenario'](this.mpid);
            },
            phase() {
                return this.$store.getters['mp_layout/getPhase'](this.mpid);
            },
            projectMeasuresForRoom() {
                return this.$store.getters['mp_project_measures/getProjectMeasuresForScenarioPhaseAndRoom'](
                    this.currentScenario,
                    this.phase,
                    this.room
                );
            },
            roomExpanded: {
                get() {
                    return this.$store.getters['mp_layout/getRoomExpanded'](this.room, this.mpid);
                },
                set(expanded) {
                    this.$store.commit('mp_layout/setRoomExpanded', {mpid: this.mpid, room: this.room, expanded});
                }
            },
            roomListFilters() {
                return this.$store.getters['mp_layout/getRoomListFilters'](this.mpid);
            }
        },

        watch: {
            async roomExpanded(newRoomExpanded) {
                if(!newRoomExpanded) {
                    return // no need to load
                }

                await this.loadRoomMeasures();
                this.$nextTick(() => {
                    $('span[title]').uitooltip({});
                })
            },
            roomListFilters: {
                handler(newFilters) {
                    if(!this.roomExpanded) {
                        return // no need to load
                    }

                    this.loadRoomMeasures();
                },
                deep: true
            }
        },

        methods: {
            updateRoomName(name) {
                this.roomName = name;
                this.persistRoomName(name);
            },
            persistRoomName: debounce(function(name) {
                let payload = {
                    newName: name,
                    room: this.room
                }
                this.$store.dispatch('mp_project_measures/updateRoom', {payload, mpid: this.mpid});
            }, 800),
            updateRoomHours: debounce(function(updateHours) {
                let payload = {
                    newHours: updateHours,
                    room: this.room
                }
                this.$store.dispatch('mp_project_measures/updateRoom', {payload, mpid: this.mpid});
            }, 800),
            toggleRoomExpanded() {
                this.roomExpanded = !this.roomExpanded;
            },
            showDeleteRoomModal() {
                this.showRoomDeleteModal = true;
            },
            deleteSelectedRoom(e) {
                this.showRoomDeleteModal = false;
                let payload = {
                    room: this.room
                }
                this.$store.dispatch('mp_project_measures/deleteRoom', {payload, mpid: this.mpid});
            },
            async loadRoomMeasures() {
                try {
                    this.loadingRoomMeasures = true;
                    await this.$store.dispatch('mp_project_measures/getProjectMeasuresForRoom', {
                        mpid: this.mpid,
                        roomId: this.room.id,
                        filters: this.roomListFilters
                    });

                }catch(err) {
                    window.notify.apiError(err);
                }finally {
                    setTimeout(() => {
                        this.loadingRoomMeasures = false;
                    }, 300);
                }
            },
            ...mapActions('mp_project_measures', [
                'addMeasureToRoom'
            ])
        },


        //jQuery UI droppable, until we work out a vue way
        mounted() {

            if(this.add_measures) {
                let vm = this;
                $('#room-' + this.room.id + '-emptymeasureslot').droppable({
                    accept: ".measure",
                    drop: function (event, ui) {
                        $(".emptymeasureslot").removeClass("droptargetgood");
                        var measureId = $(ui.draggable).attr("data-measureid");
                        var roomId = $(this).closest(".room").attr('data-roomid');

                        vm.addMeasureToRoom({
                            measureId,
                            roomId,
                            mpid: vm.mpid
                        });
                    },
                });
            }
        }


    }
</script>
<style>

    .room {
        margin-top: 20px;
    }

    .emptymeasureslot {
        height: 50px;
        line-height: 50px;
    }

    .roomcontrolbutton {
        width: 30px;
        margin-right: 10px;
        font-size: 21px;
        padding: 4px 0;
    }

</style>
