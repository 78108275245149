<script>
import Collection from "etap/admin/classes/collection";

export default {
    props: {
        appointmentId: {
            required: true,
            type: Number
        },
        appointmentType: {
            required: true,
            type: String,
        },
        appointmentableEntityTypeName: {
            required: true,
            type: String,
        },
        appointmentableEntityId: {
            required: true,
            type: Number,
        },
        notificationName: {
            required: true,
            type: String,
        },
        appointmentOptionsText: {
            required: false,
            type: String,
            default: null
        },
    },

    data() {
        return {
            sendingInvitation: false,
        }
    },

    computed: {
        appointment() {
            return this.$store.getters[
                'appointments_and_schedules/getAppointmentforAppointmentableAndIdAndAppointmentId'
            ](this.appointmentableEntityTypeName, this.appointmentableEntityId, this.appointmentId);
        },
        appointmentOptions() {
            if(this.appointment.appointment_options && this.appointment.appointment_options instanceof Collection) {
                return this.appointment.appointment_options.getModels()
            } else if(Array.isArray(this.appointment.appointment_options)) {
                return this.appointment.appointment_options;
            }

            return [];
        },
        appointmentableEntityType() {
            if(!this.appointmentableEntityTypeName) {
                return null;
            }

            return this.$store.getters['entity_types/getEntityTypeByName'](this.appointmentableEntityTypeName);
        },
    },
    methods: {
        async sendInvitation() {
            if(!this.appointmentableEntityType){
                window.notify.error('Unable to send notification', 'Error!');
                return;
            }

            this.sendingInvitation = true;
            try {
                await this.$store.dispatch('appointments_and_schedules/sendAppointmentInvitationForAppointmentable', {
                    notificationName: this.notificationName,
                    appointmentType: this.appointmentType,
                    appointmentId: this.appointment.id,
                    appointmentableEntityType: this.appointmentableEntityType,
                    appointmentableEntityId: this.appointmentableEntityId,
                });

                window.notify.success('Invitation sent successfully', 'Success!');
            }catch(err) {
                window.notify.error('Unable to send notification', 'Error!');
            }
            this.sendingInvitation = false;
        }
    }
}
</script>
<template>
    <div>
        <div :class="['flex', {'justify-between': appointmentOptionsText, 'justify-end': !appointmentOptionsText}]">
            <div v-if="appointmentOptionsText" v-html="appointmentOptionsText" />
            <button v-if="notificationName" class="button" @click="sendInvitation" :disabled="sendingInvitation">
                <i class="fa fa-spinner fa-spin" v-if="sendingInvitation"></i>
                <i v-else class="fa fa-envelope"></i>
                Send Invitation for {{ appointmentType}}
            </button>
        </div>
        <v-grid
            :data="appointmentOptions"
            :headers="['Potential Scheduled Time', '']"
            record-type="appointment-option-row"
            :grid-args="{appointmentId: appointmentId, appointmentableEntityTypeName: appointmentableEntityTypeName, appointmentableEntityId: appointmentableEntityId, appointmentType: appointmentType, noRecordsText: 'No appointment options found'}"
        ></v-grid>
    </div>
</template>
