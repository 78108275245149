<template>
    <div id="bill-accounts-qc" class="m-4">
        <h2 class="underline">Project Bill Accounts</h2>
        <div class="flex justify-end mt-2">
            <button
                v-if="maxBillAccounts === 0 || gridBillAccounts.length < maxBillAccounts && eligibilityCheckConfiguration"
                class="button mx-2"
                :class="{'disabled': hasNewBillAccount }"
                @click="searchAndAddExistingBillAccount"
                :disabled="hasNewBillAccount"
            >
                <font-awesome-icon
                    :icon="faPlus"
                ></font-awesome-icon>&nbsp;
                Add Existing Bill Account
            </button>
            <button
                v-if="maxBillAccounts === 0 || gridBillAccounts.length < maxBillAccounts"
                class="button"
                :class="{'disabled': hasNewBillAccount }"
                @click="addProjectBillAccount"
                :disabled="hasNewBillAccount"
            >
                <font-awesome-icon
                    :icon="faPlus"
                ></font-awesome-icon>&nbsp;
                Add Bill Account
            </button>
        </div>
        <v-grid
            :data="gridBillAccounts"
            record-type="bill-account-qc-row"
            grid-type="divRow"
            :grid-args="gridArgs"
        ></v-grid>
        <right-panel
            v-if="showSearchAndAddPanel"
            @close="showSearchAndAddPanel = false"
            >
            <template #header>
                <div class="mx-1 py-8 px-4 w-full border bg-lightest-gray rounded">
                    <h3>Add Existing Account to Project</h3>
                </div>
            </template>
            <div>
                <div
                    v-if="!eligibilitySearchForm"
                    style="min-height: 10vh; display: flex; align-items: center; justify-content: center;"
                >
                    <div class="text-center flex justify-center">
                        <div class="loading-bar w-1/2 text-white p-12 rounded">
                            <span class="ml-4">Loading ...</span>
                        </div>
                    </div>
                </div>
                <eligibility-container
                    v-else
                    :form-config="eligibilitySearchForm"
                    :program-track-id="project.program_track_id"
                    :program-year-id="project.program_year_id"
                ></eligibility-container>
            </div>
        </right-panel>
    </div>
</template>
<script>
    import {
        faPlus
    } from "@fortawesome/free-solid-svg-icons/faPlus";
    import { mapGetters } from "vuex";
    export default {
        props: {
            projectBillAccounts: {
                required: true,
                type: Array
            },
            project: {
                required: true,
            },
            billAccountFormConfig: {},
            options: {},
            eligibilityCheckConfiguration:{
                type: Object
            }
        },
        data() {
            return {
                showPanel: false,
                faPlus,
                showSearchAndAddPanel: false,
            }
        },
        created() {
            this.projectBillAccounts.forEach(pba => {
                this.$store.commit('bill_accounts/upsertProjectBillAccount', pba);
            });


            if(
                this.eligibilityCheckConfiguration &&
                this.eligibilityCheckConfiguration.eligibility_search_form &&
                !this.eligibilitySearchForm
            ) {
                this.$store.dispatch('form_configurations/getFormConfiguration',
                    this.eligibilityCheckConfiguration.eligibility_search_form
                );
            }

        },
        computed: {
            gridBillAccounts() {
                return this.$store.getters
                    ['bill_accounts/getProjectBillAccountsForProject'](
                    this.project.id
                )
            },
            hasNewBillAccount() {
                const newAccount = this.gridBillAccounts.find(gBA => {
                    if (!gBA.id) {
                        return true;
                    }
                })
                return typeof newAccount !== "undefined"
            },
            maxBillAccounts() {
                if (this.options.hasOwnProperty('max_bill_accounts')) {
                    return this.options.max_bill_accounts;
                }
                return 0;
            },
            billAccountDisplayFields() {
                return this.options.bill_account_display_fields;
            },
            gridArgs() {
                const displayFields = this.billAccountDisplayFields ?? [
                    {
                        label: 'Name on Account',
                        value_field: 'bill_name'
                    },
                    {
                        label: 'Account #',
                        value_field: 'account_number'
                    },
                    {
                        label: 'Account Type',
                        value_field: 'account_type'
                    },
                    {
                        label: 'Annual Electric Energy Usage',
                        value_field: 'electric_energy_usage'
                    },
                    {
                        label: 'Annual Electric Energy Cost',
                        value_field: 'electric_energy_cost'
                    },
                    {
                        label: 'Annual Gas Energy Usage',
                        value_field: 'gas_energy_usage'
                    },
                    {
                        label: 'Annual Gas Energy Cost',
                        value_field: 'gas_energy_cost'
                    },
                ]
                const allowCopy = (this.maxBillAccounts === 0 || this.gridBillAccounts.length < this.maxBillAccounts);
                const allowDelete = (this.maxBillAccounts !== 1);
                return {
                    formConfig: this.billAccountFormConfig,
                    displayFields,
                    allowCopy,
                    allowDelete,
                    noRecordsText: "No bill accounts found"
                }
            },
            eligibilitySearchForm() {
                if(
                    !this.eligibilityCheckConfiguration &&
                    !this.eligibilityCheckConfiguration.eligibility_search_form
                ) {
                    return null;
                }
                return this.$store.getters['form_configurations/getFormConfigurationByName'](
                    this.eligibilityCheckConfiguration.eligibility_search_form
                )
            }
        },
        methods: {
            addProjectBillAccount() {
                this.$store.dispatch('bill_accounts/addProjectBillAccount', {
                    project_id: this.project.id
                })
            },
            searchAndAddExistingBillAccount() {
                this.showSearchAndAddPanel = true;
            }
        }
    }
</script>
