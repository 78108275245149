<script>
import { FormFieldMixin, HasOptionsMixin } from "laravel-vue-forms";
import MultiSelect from "vue-multiselect";

export default {
  name: "appointment-schedules-field",
  mixins: [FormFieldMixin, HasOptionsMixin],

  components: {
    MultiSelect,
  },

  props: {
    showMultiselectLabels: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    selectLabel: {
      type: String,
      default: "Press enter to select",
    },
    deselectLabel: {
      type: String,
      default: "Press enter to remove",
    },
  },

  data() {
    return {
      managingSchedules: false,
    };
  },

  computed: {
    currentUser() {
      return window.MAT.user;
    },
  },

  methods: {
    updateValue(value) {
        this.form.errors.clear(this.fieldConfig.value_field);
        if (value) {
            this.$emit("input", value[this.fieldConfig.optionValueField]);
        } else {
            this.$emit("input", null);
        }
    },
    closeManagingSchedules() {
      this.managingSchedules = false;
      this.getOptions();
    },
  },
};
</script>
<template>
  <div
    class="form-group"
    :id="fieldName + '-select-field'"
    :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }"
  >
    <div class="flex justify-between items-center" style="width: 95%">
      <label class="form-control-label" v-if="showLabel">
        <span v-html="fieldConfig.label"></span>
        <span class="required" v-if="fieldConfig.field_extra.required"
          >&nbsp;&nbsp;(*)</span
        >
        <span
          v-if="withHelpIcon"
          :class="fieldConfig.field_extra.withIcon"
          :title="fieldConfig.field_extra.helpText"
        ></span>
      </label>
      <div>
        <button
          class="p-1 bg-primary text-white"
          @click="managingSchedules = true"
        >
          <i class="fa fa-edit"></i>Manage Schedules
        </button>
      </div>
    </div>

    <multi-select
      :value="optionValue"
      :options="fieldConfig.options"
      :track-by="fieldConfig.optionValueField"
      :label="fieldConfig.optionLabelField"
      :placeholder="fieldConfig.label"
      @input="updateValue"
      class="form-control"
      :disabled="fieldConfig.disabled === 1 || fieldConfig.disabled === true"
      :show-labels="showMultiselectLabels"
      :select-label="selectLabel"
      :deselect-label="deselectLabel"
      :allow-empty="allowEmpty"
    ></multi-select>
    <span
      class="help-block"
      v-if="form.errors.has(this.fieldConfig.value_field)"
    >
      {{ form.errors.get(this.fieldConfig.value_field, true) }}
    </span>
    <div v-if="hasHelpText">
      <span v-html="fieldConfig.field_extra.helpText"></span>
    </div>
    <modal
      v-if="managingSchedules"
      modal-width="80%"
      @close="closeManagingSchedules"
    >
      <template #header>
        <h2>Your Schedules</h2>
      </template>
      <template #body>
        <manage-schedules-for-user
          class="min-h-50vh"
          :user="currentUser"
          :current-user-schedules="fieldConfig.options"
        ></manage-schedules-for-user>
      </template>
    </modal>
  </div>
</template>
