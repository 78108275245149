

export const getSchedulesForUser = state => {
    return (userId) => {

        if(
            !state.schedules[userId] ||
            !Array.isArray(state.schedules[userId])
        ) {
            return [];
        }

        return state.schedules[userId];
    }
}


export const getApiUrlForAppointmentableAndId = state => {
    return (appointmentableEntityType, id) => {
        switch(appointmentableEntityType.name) {
            case 'project':
                return '/api/projects/' + id + '/appointments';
                break;
            default:
                throw new Error("Invalid appointmentable entity type: " + appointmentableEntityType.name);
        }
    }
}


export const getAppointmentsForAppointmentableAndId = state => {

    return (appointmentableEntityTypeName, id) => {

        if(
            !state.appointments[appointmentableEntityTypeName] ||
            !state.appointments[appointmentableEntityTypeName][id]
        ) {
            return [];
        }

        return state.appointments[appointmentableEntityTypeName][id];
    }
}

export const getAppointmentforAppointmentableAndIdAndAppointmentId = state => {
    return (appointmentableEntityTypeName, id, appointmentId) => {
        if(
            !state.appointments[appointmentableEntityTypeName] ||
            !state.appointments[appointmentableEntityTypeName][id]
        ) {
            return null;
        }

        return state.appointments[appointmentableEntityTypeName][id].find(appointment => appointment.id == appointmentId);
    }
}
