<template>
    <div class="preview-pdf">
        <button class="inputbutton1" @click.prevent="handleShowModal" :disabled="isLoading || disabled">
            <span v-if="isLoading"></span> {{ openLabel }}</button>
        <modal
            v-if="showModal"
            @close="handleCloseModal"
            modal-width="90%"
        >
            <template slot="body">
                <div class="mt-6">
                    <div v-if="pdfError !== null" class="flex justify-center items-center min-h-50vh">
                        <div class="text-md">{{ pdfError }}</div>
                    </div>
                    <div v-else>
                        <div v-show="isLoading" class="flex justify-center items-center min-h-50vh">
                            <span class="fa fa-spinner fa-spin fa-5x"></span>
                        </div>
                        <div v-show="!isLoading">
                            <div class="controls-row flex justify-between">
                                <slot name="controls-row">
                                    <div class="flex text-left">
                                        <button class="inputbutton1" @click="downloadPdf">Download</button>
                                    </div>
                                </slot>
                                <button class="button my-4 mx-4" @click="refresh" v-if="showRegenerate">{{ regenerateLabel }}</button>
                            </div>
                            <div v-if="pdfDoc" id="pdf-container" class="overflow-hidden h-80vh">
                                <div id="pdf-viewer" class="overflow-scroll"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </template>
        </modal>
    </div>
</template>

<script>
    export default {
        props: {
            previewUrl: {
                required: true,
                type: String
            },
            downloadUrl: {
                required: true,
                type: String
            },
            openLabel: {
                type: String,
                default: 'Preview PDF',
            },
            showRegenerate: {
                type: Boolean,
                default: false,
            },
            regenerateLabel: {
                type: String,
                default: 'Regenerate',
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                isLoading: false,
                showModal: false,
                pdfDoc: null,
                pageNum: 1,
                numPages: 0,
                scale: 1.5,
                currentRandomParam: null,
                pdfError: null,
            }
        },

        created() {
            this.currentRandomParam = Math.random().toFixed(2) * 100;
        },

        computed: {
            pdfPreviewUrl() {

                if(this.previewUrl.includes('?')) {
                    return this.previewUrl + '&_=' + this.currentRandomParam;
                }

                return this.previewUrl + '?_=' + this.currentRandomParam;
            }
        },



        methods: {
            handleShowModal() {
                this.isLoading = true;
                this.showModal = true;

                let url = {
                    url: this.pdfPreviewUrl,
                };

               this.renderPdf(url);
            },
            refresh() {
                this.currentRandomParam = Math.random();

                new Promise((resolve, reject) => {
                    this.refreshPdf(this.pdfPreviewUrl);
                    return resolve(1);
                }).then((res) => {
                    this.isLoading = false;
                });
            },
            handlePages(page) {
                const viewport = page.getViewport({scale: this.scale});
                const pdfContainer = document.getElementById('pdf-viewer');

                //Cannot use same canvas for every page
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');

                // Prepare canvas using PDF page dimensions
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };

                //Draw on Canvas
                page.render(renderContext);

                this.pageNum++;

                // Add it to the container
                pdfContainer.appendChild(canvas);

                //Load Next Page
                if(this.pdfDoc && this.pageNum <= this.numPages) {
                    this.pdfDoc.getPage(this.pageNum).then(this.handlePages);
                }else {
                    this.isLoading = false;
                }
            },

            refreshPdf(url) {
                if(this.pdfDoc) {
                    this.pdfDoc.destroy().then(() => {
                        this.isLoading=true;
                        this.numPages = 0;
                        this.pageNum = 1;
                        this.pdfDoc = null;
                        this.renderPdf(url);

                    });
                } else {
                    this.renderPdf(url);
                }
            },
            renderPdf(url) {
                window.pdfjsLib.getDocument(url).promise.then((pdf) => {
                    this.pdfDoc = pdf;
                    this.numPages = pdf.numPages;
                    pdf.getPage( 1 ).then( this.handlePages );
                }).catch(error => {
                    this.pdfError = 'Sorry, we’re unable to generate the report.';
                    this.isLoading = false;
                });
            },
            handleCloseModal()
            {
                this.showModal = false;
                this.isLoading = false;
                if(this.pdfDoc) {
                    this.pdfDoc.destroy();
                }
                this.pdfDoc = null;
                this.numPages = 0;
                this.pageNum = 1;
            },
            downloadPdf() {
                window.open(this.downloadUrl)
            }
        }
    }
</script>

<style>
    #pdf-viewer {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        overflow: auto;
        min-height: 500px;
        position: relative;
    }
    #pdf-viewer canvas {
        display: block;
        margin: 0 auto;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
    }
</style>

