<script>
export default {
    props: {
        record: {
            required: true,
            type: Object
        },
        args: {
            type: Object,
        }
    },

    data() {
        return {
            showForm: false,
            showConfirmDelete: false
        }
    },

    computed: {
        appointmentIsSelected() {
            if(!this.args.selectedAppointmentId) {
                return false;
            }

            return parseInt(this.args.selectedAppointmentId) === this.record.id;
        },
        appointmentEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName']('appointment');
        },
        appointmentableEntityType() {
            if(!this.args.appointmentableEntityTypeName) {
                return null;
            }

            return this.$store.getters['entity_types/getEntityTypeByName'](this.args.appointmentableEntityTypeName);
        },
        appointmentEntityForm() {
            if(!this.appointmentEntityType) {
                return null;
            }

            const formConfig = this.$store.getters[
                "form_configurations/getFormConfigurationById"
                ](this.appointmentEntityType.default_form_configuration_id);

            if(!this.appointmentTypeName || !formConfig) {
                return formConfig;
            }

            formConfig.fields.each(ff => {
                if(ff.value_field !== 'appointment_type_id') {
                    return;
                }

                ff.field_extra.default = this.appointmentTypeName;
            });

            return formConfig;
        },
        appointmentApiBaseUrl() {
            if(!this.appointmentableEntityType || !this.args.appointmentableEntityId) {
                return null;
            }


            return this.$store.getters[
                "appointments_and_schedules/getApiUrlForAppointmentableAndId"
                ](this.appointmentableEntityType, this.args.appointmentableEntityId) + '/' + this.record.id;
        },
    },

    methods: {
        toggleForm() {

            if(this.showForm) {
                window.location.hash = '';
            }

            this.showForm = !this.showForm;
        },
        updateAppointmentForAppointmentable(appointment) {

            if(!this.appointmentableEntityType || !this.args.appointmentableEntityId) {
                throw new Error("Cannot update appointment for appointmentable entity type and id");
            }

            this.$store.commit('appointments_and_schedules/updateAppointmentForAppointmentable', {
                appointmentableEntityType: this.appointmentableEntityType,
                appointmentableEntityId: this.args.appointmentableEntityId,
                appointment
            });

            if(window.Bus) {
                window.Bus.$emit('entity-saved', {
                    entityType: this.appointmentEntityType,
                    entity: appointment
                });
            }
            window.notify.success('Appointment was successfully updated', 'Updated!');
        },
        async deleteAppointmentForAppointmentable() {
            if(!this.appointmentableEntityType || !this.args.appointmentableEntityId) {
                throw new Error("Cannot delete appointment for missing appointmentable entity type and id");
            }

            await this.$store.dispatch(
                'appointments_and_schedules/deleteAppointmentForAppointmentable',
                {
                    appointmentableEntityType: this.appointmentableEntityType,
                    appointmentableEntityId: this.args.appointmentableEntityId,
                    appointmentId: this.record.id
                }
            )

            window.notify.success('Appointment was successfully deleted', 'Deleted!');
        }
    }
}
</script>
<template>
    <div>
        <div class="flex items-center p-2 my-1 shadow-md" @click="toggleForm" :class="{'border-solid-primary': appointmentIsSelected}">
            <div
                class="cursor-pointer ml-2 md:ml-8 flex justify-between items-center w-full"
            >
                <div class="flex justify-around">
                    <div class="mx-2">
                        <i class="fa fa-chevron-down" v-if="showForm"></i>
                        <i class="fa fa-chevron-right" v-else></i>
                    </div>
                </div>
                <div class="w-2/3 flex justify-between">
                    <div class="w-1/3"><span class="text-bold">Title:</span>&nbsp;&nbsp;{{ record.title }}</div>
                    <div class="w-1/3"><span class="text-bold">Updated At:</span>&nbsp;&nbsp;{{ record.updated_at }}</div>
                    <div class="w-1/3"><span class="text-bold">Scheduled Date/Time:</span>&nbsp;&nbsp;{{ record.scheduled_appointment_at }}</div>
                </div>
                <div class="flex">
                    <div class="mx-4 md:mx-2 cursor-pointer" @click.stop="toggleForm">
                        <span class="fa fa-edit fa-lg"></span>
                    </div>
                    <div class="cursor-pointer" @click.stop="showConfirmDelete = true">
                        <i class="fa fa-times fa-lg"></i>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide">
            <div class="shadow-inner w-full py-4" v-if="showForm">
                <tabs>
                    <tab name="Appointment Details">
                        <vue-form
                            v-if="appointmentEntityForm && appointmentApiBaseUrl"
                            :form-config="appointmentEntityForm"
                            :form-data="record"
                            :form-submit-url="appointmentApiBaseUrl"
                            :use-json-api="true"
                            :actions="[{name: 'submitForm', label: 'Update Appointment', action: 'submitForm'}]"
                            @updated="updateAppointmentForAppointmentable"
                        ></vue-form>
                    </tab>
                    <tab name="Appointment Options">
                        <manage-appointment-options
                            :appointment-id="record.id"
                            :appointment-type="args.appointmentTypeName"
                            :appointmentable-entity-type-name="args.appointmentableEntityTypeName"
                            :appointmentable-entity-id="args.appointmentableEntityId"
                            :notification-name="args.notificationName"
                            :appointment-options-text="args.appointmentOptionsText"
                        ></manage-appointment-options>
                    </tab>
                </tabs>
            </div>
        </transition>
        <modal v-if="showConfirmDelete" @close="showConfirmDelete = false" :is-confirm="true" modal-width="50%" @confirmed="deleteAppointmentForAppointmentable">
            <template #header>
                <h2>Delete Appointment?</h2>
            </template>
            <template #body>
                <div>
                    <p>Are you sure you want to delete this appointment?</p>
                    <p class="mt-2">If this is the selected active appointment, it will remove it from any appointment from being active</p>
                </div>
            </template>
        </modal>
    </div>
</template>
<style scoped>
.slide-enter-active {
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
