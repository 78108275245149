<script>
import {FormFieldMixin} from "laravel-vue-forms";
export default {

    mixins: [FormFieldMixin],

    data() {


        let localValue = {
            sunday: {
                enabled: false,
                availableTimes: [{start: null, end: null}],
            },
            monday: {
                enabled: false,
                availableTimes: [{start: null, end: null}],
            },
            tuesday: {
                enabled: false,
                availableTimes: [{start: null, end: null}],
            },
            wednesday: {
                enabled: false,
                availableTimes: [{start: null, end: null}],
            },
            thursday: {
                enabled: false,
                availableTimes: [{start: null, end: null}],
            },
            friday: {
                enabled: false,
                availableTimes: [{start: null, end: null}],
            },
            saturday: {
                enabled: false,
                availableTimes: [{start: null, end: null}],
            }
        };
        if(this.value && typeof this.value === "object" && !Array.isArray(this.value)) {
            localValue = this.value
        }

        return {
            testing: true,
            localValue,
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        }
    },

    watch: {
        localValue: {
            deep: true,
            handler: function() {
                this.$emit("input", this.localValue);
            }
        }
    },

    methods: {
        enableForDay(day, value) {
            this.localValue[day].enabled = value;
        },
        addTimeSlotToDay(day) {
            this.localValue[day].availableTimes.push({
                start: null,
                end: null
            })
        },
        removeTimeSlotFromDay(day, index) {
            this.localValue[day].availableTimes.splice(index, 1);
        }
    }

}
</script>
<template>
    <div>
        <div v-for="day in days" class="flex justify-between py-2 border-b">
            <div class="flex items-center w-third">
                <form-checkbox :value="localValue[day.toLowerCase()].enabled" @input="(value) => {enableForDay(day.toLowerCase(), value)}" :field-name="day.toLowerCase() + '-enabled'" :find-in-form="false"></form-checkbox>
                <div>{{ day }}</div>
            </div>
            <div class="w-half">
                <div v-for="(timeItem, index) in localValue[day.toLowerCase()].availableTimes">
                    <user-schedule-builder-time-item
                        :available-time="timeItem"
                        @removeItem="removeTimeSlotFromDay(day.toLowerCase(), index)"
                    ></user-schedule-builder-time-item>
                </div>
            </div>
            <div>
                <button class="button" @click="addTimeSlotToDay(day.toLowerCase())">
                    <i class="fa fa-plus"></i>&nbsp;Add Time
                </button>
            </div>
        </div>
    </div>
</template>
