<script>

export default {
    props: {
        userSchedule: {
            required: true,
            type: Object
        }
    },

    data() {
        return {

        }
    },

    computed: {
        userScheduleEntityType() {
            return this.$store.getters[
                "entity_types/getEntityTypeByName"
                ]('user_schedule');
        },
        userScheduleEntityForm() {
            if(!this.userScheduleEntityType) {
                return null;
            }

            return this.$store.getters[
                "form_configurations/getFormConfigurationById"
                ](this.userScheduleEntityType.default_form_configuration_id);
        },
    },
    methods: {
        commitNewSchedule(updatedSchedule) {
            this.$store.commit("appointments_and_schedules/updateUserSchedule", updatedSchedule);
        }
    }
}
</script>
<template>
    <div>
        <vue-form
            :form-config="userScheduleEntityForm"
            :form-data="userSchedule"
            :form-submit-url="'/api/users/' + userSchedule.user_id + '/schedules/' + userSchedule.id"
            :actions="[{name: 'submit', action: 'submitForm', label: 'Save Schedule'}]"
            @updated="commitNewSchedule"
            :use-json-api="true"
        ></vue-form>
    </div>
</template>
