<template>
    <div class="row">
        <div class="col-xs-11">
            <div class="form-group" id="eligibility-form">
                <vue-form
                    :form-config="formConfig"
                    :form-data="searchParams"
                    :actions="formActions"
                    @eligibility-search="handleEligibilitySearch"
                    @create-blank-project="handleCreateBlankProject"
                ></vue-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        formConfig: {
            required: true,
            type: Object
        },
        searchParams: {
            required: true,
            type: Object
        },
        eligibilityConfig: {
            required: false,
            type: Object,
            default: function() {
                return {};
            }
        }
    },
    computed: {
        canBypassEligibility() {
            if(!this.eligibilityConfig?.bypass_eligibility_ability) {
                return false;
            }

            return window.MAT.permissions.filter(permission => {
                return permission.name === this.eligibilityConfig.bypass_eligibility_ability
            }).length > 0;
        },
        bypassEligibilityBtnText() {
            return this.eligibilityConfig?.bypass_eligibility_button_text ?? 'Create Blank Project'
        },
        formActions() {
            let formActions = [
                {
                    name: 'eligibility_search',
                    label: 'Search',
                    action: 'eligibility-search'
                },
            ];

            if(this.canBypassEligibility) {
                formActions.push({
                    name: 'create_blank_project',
                    label: this.bypassEligibilityBtnText,
                    action: 'create-blank-project'
                });
            }

            return formActions;
        }
    },
    methods: {
        handleEligibilitySearch(data) {
            this.$emit('filter-list', data);
        },
        handleCreateBlankProject(data) {
            if(
                !this.canBypassEligibility ||
                !data?.program_track_id ||
                !data?.program_year_id
            ) {
                window.notify.error("No permission to create blank project.");
            }

            window.location = '/project/create?program_track_id=' + data.program_track_id + '&program_year_id=' + data.program_year_id;
        }
    }
}
</script>
<style>
#eligibility-form .vue-form .form-group {
    margin: 0px;
}
#eligibility-form .vue-form .form-group .form-control {
    margin: 0px;
}
#eligibility-form .vue-form .form-group .form-control-label {
    margin: 5px 0px;
}
</style>
