<template>
    <div class="dsmc-api-message"
         v-if="message"
         :class="{ 'error': message_type === 'error', 'success': message_type === 'success', 'running': message_type === 'running'}">
        <p v-html="message"></p>
        <div class="actions" v-if="hasReSend">
            <button class="inputbutton1" @click="resendAction">Re-send project to DSMC</button>
        </div>
        <div v-if="message_type === 'running'">
            <span class="fa fa-2x fa-spinner fa-spin"></span>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {

        props: {
            project: {
                required: true,
                type: Object
            },
            trigger: {
                required: true,
                type: String
            },
            args: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        data() {
            return {
                status: {},
                currentIntervalCheck: {} // Interval holder for checking if a new action send is completed
            }
        },

        computed: {
            message() {
                var message = '';
                switch(this.message_type) {
                    case 'error':
                        message = '<h3>Application error in sending project to DSMC.  Please contact an administrator for additional details</h3>';
                        if(this.status.output && this.status.output.error) {
                            message = '<h3>' + this.status.output.error + '</h3>';
                            if(Array.isArray(this.status.output.messages)) {
                                message += '<ul>';
                                this.status.output.messages.forEach(eMessage => {
                                    message += '<li>' + eMessage + '</li>';
                                })
                                message += '</ul>';
                            }
                        }
                        break;
                    case 'success':
                        message = '<h3>Project successfully pushed to DSMC</h3>'
                        break;
                    case 'running':
                        message = '<h3>Your project push to DSMC is currently re-running, please wait until it is completed';
                        break;
                }

                return message;
            },
            message_type() {
                if(this.status.status === 'failed' || this.status.status === 'cancelled') {
                    this.clearIntervalCheck();
                    return 'error';
                } else if(this.status.status && this.status.status === 'finished') {
                    this.clearIntervalCheck();
                    return 'success';
                } else if(this.status.status) {
                    return 'running';
                }

                return null;
            },
            hasReSend() {
                if(this.message_type === 'error') {
                    return true;
                }
                return false;
            }
        },

        created() {
            this.getAPIMessage();
        },

        methods: {
            getAPIMessage() {
                axios.get('/api/dsmc_api/logs/' + this.project.id, {
                    params: {
                        trigger: this.trigger
                    }
                }).then( response => {

                    this.status = response.data;

                }).catch( error => {
                    window.notify.apiError(error);
                });
            },
            resendAction() {
                let newArgs = this.args;
                if(this.status.input && this.status.input.args && this.status.input.args.updateType) {
                    newArgs.updateType = this.status.input.args.updateType
                }

                axios.post('/api/dsmc_api/resend/' + this.project.id, {
                    action_type: this.trigger,
                    args: newArgs
                }).then( response => {

                    this.status = response.data;
                    this.startInterval();

                }).catch( error => {
                    window.notify.apiError(error);
                })
            },
            startInterval() {
                if(this.currentIntervalCheck === null) {
                    this.currentIntervalCheck = setInterval(this.getAPIMessage, 5000);
                }
            },
            clearIntervalCheck() {
                if(this.currentIntervalCheck !== null) {
                    clearInterval(this.currentIntervalCheck);
                    this.currentIntervalCheck = null;
                }
            }
        }
    }
</script>
