<script>
import {FormFieldMixin} from "laravel-vue-forms";
export default {
    mixins: [FormFieldMixin],

    props: {
        appointmentableType: {
            type: String
        }
    },

    data() {
        return {
            entityTypeName: null,
            showNewAppointmentForm: false,
            defaultNewAppointmentData: {
                user_id: window.MAT.user.id,
                appointment_type_id: null
            },
            addNewAppointmentText: 'Note: Upon creating a new appointment, this will become the selected appointment',
            appointmentTypeName: null,
            notificationName: null,
            appointmentOptionsText: null,
        }
    },

    async created() {

        if (
            this.findInForm &&
            this.form &&
            this.form.formConfig &&
            (Array.isArray(this.form.formConfig.fields) ||
                typeof this.form.formConfig.fields[Symbol.iterator] ===
                "function")
        ) {
            this.form.formConfig.fields.forEach(field => {
                if (field.name !== this.fieldName) {
                    return;
                }

                const fieldExtra = this.getFormFieldFieldExtra(field);
                this.entityTypeName = fieldExtra.appointmentable_type;

                if(fieldExtra.add_appointment_text) {
                    this.addNewAppointmentText = fieldExtra.add_appointment_text;
                }

                if(fieldExtra.appointment_type) {
                    this.appointmentTypeName = fieldExtra.appointment_type;
                }

                if(fieldExtra.notification_name) {
                    this.notificationName = fieldExtra.notification_name;
                }

                if(fieldExtra.appointment_options_text) {
                    this.appointmentOptionsText = fieldExtra.appointment_options_text;
                }
            });

        }else {
            this.entityTypeName = this.appointmentableType;
        }

        if(!this.entityTypeName) {
            throw new Error('No appointmentable type found');
        }

        if(!this.form.data.id) {
            throw new Error("no appointmentable identifier found");
        }

        try {
            if(!this.appointmentEntityType) {
                await this.$store.dispatch('entity_types/getEntityTypeForName', 'appointment');
            }
            if(!this.appointmentableEntityType) {
                await this.$store.dispatch('entity_types/getEntityTypeForName', this.entityTypeName);
            }
            await this.$store.dispatch('appointments_and_schedules/getAppointmentsForAppointmentableAndId', {
                appoinmentableEntityType: this.appointmentableEntityType,
                id: this.form.data.id,
                include: ['appointment_options']
            });

            if(!this.appointmentEntityForm && this.appointmentEntityType.default_form_configuration_id) {
                await this.$store.dispatch(
                    'form_configurations/getFormConfigurationById',
                    this.appointmentEntityType.default_form_configuration_id,
                );
            }

        }catch(err) {
            console.log(err);
            window.notify.apiError(err);
        }

    },

    computed: {
        appointmentEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName']('appointment');
        },
        appointmentableEntityType() {
            if(!this.entityTypeName) {
                return null;
            }

            return this.$store.getters['entity_types/getEntityTypeByName'](this.entityTypeName);
        },
        appointmentsForAppointmentable() {
            if(!this.appointmentableEntityType || !this.form.data.id) {
                return [];
            }

            return this.$store.getters[
                'appointments_and_schedules/getAppointmentsForAppointmentableAndId'
            ](this.appointmentableEntityType.name, this.form.data.id);
        },
        appointmentEntityForm() {
            if(!this.appointmentEntityType) {
                return null;
            }

            const formConfig = this.$store.getters[
                "form_configurations/getFormConfigurationById"
                ](this.appointmentEntityType.default_form_configuration_id);

            if(!this.appointmentTypeName || !formConfig) {
                return formConfig;
            }

            formConfig.fields.each(ff => {
                if(ff.value_field !== 'appointment_type_id') {
                    return;
                }

                ff.field_extra.default = this.appointmentTypeName;
            });

            return formConfig;
        },
        appointmentApiBaseUrl() {
            if(!this.appointmentableEntityType || !this.form.data.id) {
                return null;
            }


            return this.$store.getters[
                "appointments_and_schedules/getApiUrlForAppointmentableAndId"
            ](this.appointmentableEntityType, this.form.data.id);
        },
        currentAppointmentForField() {
            if(!this.value || this.appointmentsForAppointmentable.length === 0) {
                return null;
            }

            return this.appointmentsForAppointmentable.find(a => a.id === parseInt(this.value));
        }
    },

    methods: {
        addCreatedAppointmentToAppointmentable(appointment) {

            if(!this.appointmentableEntityType || !this.form.data.id) {
                throw new Error("Cannot update appointment for appointmentable entity type and id");
            }

            this.$store.commit('appointments_and_schedules/updateAppointmentForAppointmentable', {
                appointmentableEntityType: this.appointmentableEntityType,
                appointmentableEntityId: this.form.data.id,
                appointment
            });

            this.showNewAppointmentForm = false;
            this.$emit('input', appointment.id);

            window.notify.success('Appointment was successfully created', 'Created!');
        },
        updateSelectedAppointment(newAppointmentId) {
            this.$emit('input', newAppointmentId);
        }
    }
}
</script>
<template>
    <div class="mb-4">
        <div class="py-4 border-b flex justify-between items-center">
            <div class="flex items-center justify-around w-2/3">
                <span class="text-bold">Current Appointment Selected: </span>
                <form-select
                    class="w-third"
                    field-name="appointment-selection-field"
                    :options="appointmentsForAppointmentable"
                    :value="value"
                    @input="updateSelectedAppointment"
                    :find-in-form="false"
                    option-value-field="id"
                    option-label-field="title"
                ></form-select>
            </div>
            <button class="button" @click="showNewAppointmentForm = true">
                <i class="fa fa-plus"></i>&nbsp;Add <span v-if="appointmentTypeName">{{ appointmentTypeName}}</span><span v-else>appointment</span>
            </button>
        </div>
        <h4>Available {{ fieldConfig.label }}</h4>
        <div v-if="appointmentsForAppointmentable.length > 0">
            <v-grid
                :data="appointmentsForAppointmentable"
                grid-type="divRow"
                record-type="manage-appointments-appointment"
                :grid-args="{appointmentableEntityTypeName: entityTypeName, appointmentableEntityId: form.data.id, selectedAppointmentId: value, appointmentTypeName, notificationName, appointmentOptionsText}"
            ></v-grid>
        </div>
        <div v-else>
            No appointments found
        </div>
        <modal v-if="showNewAppointmentForm" @close="showNewAppointmentForm = false" modal-width="95%">
            <template #header>
                <div>
                    Add <span v-if="appointmentTypeName">{{ appointmentTypeName}}</span><span v-else>appointment</span>
                </div>
            </template>
            <template #body>
                <div class="min-h-75vh">
                    <p>{{ addNewAppointmentText }}</p>
                    <vue-form
                        v-if="appointmentEntityForm"
                        :form-config="appointmentEntityForm"
                        :form-data="defaultNewAppointmentData"
                        :form-submit-url="appointmentApiBaseUrl"
                        @created="addCreatedAppointmentToAppointmentable"
                        :use-json-api="true"
                        @cancel-form="showNewAppointmentForm = false"
                    ></vue-form>
                </div>
            </template>
        </modal>
    </div>
</template>
