<template>
    <div class="project-measure-details mb-4">
        <div class="flex justify-between items-center w-full">
            <div class="measureNameText w-full" :id="'pmDetailsMeasureName-' + this.projectMeasure.id">
                <span v-html="projectMeasure.measure.name"></span> Details <span v-if="displaySharedUUid">- {{ displaySharedUUid }}</span>
            </div>
            <button ref="close-button" @click.stop="closeDetails" class="m-4 p-2">
                <svg class="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                </svg>
            </button>
        </div>

        <hr />

        <div
            class="flex justify-center"
            v-if="measureEquipmentEligibilityItem && measureEquipmentEligibilityItem.measure_eligibility_requirements_text"
        >
            <div
                class="my-4 p-2 border-b border-t bg-lightest-gray rounded w-4/5"
                v-html="measureEquipmentEligibilityItem.measure_eligibility_requirements_text"></div>
        </div>

        <div v-if="showAudits" class="row">
            <div class="col-md-12 panel" >
                <div class="panel-body">
                    Inspection changes
                    <ul>
                        <li v-for="pmAuditMessage in inspectionAudits">
                           {{ pmAuditMessage}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <vue-form
            v-if="editFormConfig && projectMeasureData"
            :form-config="editFormConfig"
            :form-data="projectMeasureData"
            @cancel-form="closeDetails"
            @close-form="closeDetails"
            @updated="updateProjectMeasure"
            :form-submit-url="'/api/projects/' + projectMeasure.project_id + '/scenarios/' + projectMeasure.scenario_id  + '/rooms/' + projectMeasure.room_id +'/measures/' + projectMeasure.id"
            :disabled="!edit_measures"
            :close-on-save="false"
            class="col-xs-12"
            ></vue-form>
    </div>
</template>
<script>
    import MeasurePickerUtils from 'etap/mixins/measure_picker/measure_picker_utils';
    import InspectionAudits from 'etap/mixins/measure_picker/inspection_audits';
    import { mapState } from 'vuex';

    export default {

        mixins: [MeasurePickerUtils, InspectionAudits ],

        async mounted() {

            await this.$store.dispatch('equipment_library/getMeasureEligibility', {
                measureId: this.projectMeasure.measure_id,
                projectId: this.project.id
            });

            setTimeout(() => {
                let pmElement = document.getElementById('pmDetailsMeasureName-' + this.projectMeasure.id);
                pmElement.scrollIntoView({ behavior: 'smooth'});
            }, 25);
        },

        computed: {
            project() {
                return this.$store.getters['mp_layout/getProject'](this.mpid);
            },
            editFormConfig() {
                return this.$store.getters['mp_layout/getEditFormConfig'](this.mpid);
            },
            projectMeasure() {
                return this.$store.getters['mp_layout/getCurrentProjectMeasure'](this.mpid);
            },
            projectMeasureData() {
                var clonedPM = JSON.parse(JSON.stringify(this.projectMeasure));
                var data = {};
                this.editFormConfig.fields.forEach(field => {
                    Object.assignFormValueByString(data, field.value_field, Object.getFormValueByString(clonedPM, field.value_field));
                })

                delete data.eav_values;

                data.id = this.projectMeasure.id;
                data.project_id = this.projectMeasure.project_id;
                data.scenario_id = this.projectMeasure.scenario_id;
                data.phase_id = this.projectMeasure.phase_id;
                data.program_year_id = this.project.program_year_id;
                data.program_track_id = this.project.program_track_id;
                data.measure_id = this.projectMeasure.measure_id;
                data.measure = this.projectMeasure.measure;

                return data;
            },
            displaySharedUUid() {
                const sharedParts = this.projectMeasure.shared_uuid?.split('-');

                if(sharedParts) {
                    return sharedParts[sharedParts.length - 1]
                }

                return null;
            },
            measureEquipmentEligibilityItem() {
                if (!this.projectMeasure) {
                    return null;
                }
                return this.$store.getters['equipment_library/getMeasureEquipmentEligibilityItemForMeasureId'](
                    this.projectMeasure.measure_id
                )
            },
        },

        methods: {
            closeDetails() {
                this.$store.dispatch('mp_layout/hideProjectMeasureDetails', this.mpid);
            },
            updateProjectMeasure(projectMeasure) {
                this.$store.dispatch('mp_project_measures/updateProjectMeasure', {projectMeasure, mpid: this.mpid});
                window.notify.message('Project Measure Updated!', 'success');
            },

        }
    }
</script>
<style scoped>

.w-4 {
    width: 1rem;
}

.h-4 {
    height: 1rem;
}

.fill-slate-400 {
    fill: #a0aec0;
}

.group-hover\:fill-slate-600:hover {
    fill: #4a5568;
}

.pointer-events-none {
    pointer-events: none;
}
</style>
