<script>
import { mapActions } from "vuex";
import UserSchedule from "../../admin/classes/models/user_schedule";

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        currentUserSchedules: {
            required: true,
            type: Array,
        },
    },

    data() {
        return {
            selectedScheduleId: null,
            showAddScheduleModal: false,
            savingNewSchedule: false,
            newScheduleName: "",
        };
    },

    async created() {
        this.$store.commit("appointments_and_schedules/setSchedulesForUser", {
            userId: this.user.id,
            schedules: this.currentUserSchedules.map((schedule) => {
                return new UserSchedule(schedule);
            })
        });

        if(this.schedulesForUser.length > 0) {
            this.selectedScheduleId = this.schedulesForUser[0].id;
        }

        if(!this.userScheduleEntityType) {
            await this.getEntityTypeForName('user_schedule');
        }

        if(!this.userScheduleEntityForm && this.userScheduleEntityType.default_form_configuration_id) {
            await this.getFormConfigurationById(
                this.userScheduleEntityType.default_form_configuration_id
            );
        }
    },

    computed: {
        userScheduleEntityType() {
            return this.$store.getters[
                "entity_types/getEntityTypeByName"
            ]('user_schedule');
        },
        userScheduleEntityForm() {
            if(!this.userScheduleEntityType) {
                return null;
            }

            return this.$store.getters[
                "form_configurations/getFormConfigurationById"
            ](this.userScheduleEntityType.default_form_configuration_id);
        },
        schedulesForUser() {
            return this.$store.getters[
                "appointments_and_schedules/getSchedulesForUser"
            ](this.user.id);
        },
        modalWidth() {
            if (window.innerWidth < 768) {
                return "90%";
            }
            return "30%";
        },
        selectedUserSchedule() {
            if(!this.selectedScheduleId) {
                return null;
            }

            return this.schedulesForUser.find((schedule) => {
                return schedule.id === this.selectedScheduleId;
            });
        }
    },

    methods: {
        ...mapActions("appointments_and_schedules", ["createScheduleForUser"]),
        ...mapActions("entity_types", ['getEntityTypeForName']),
        ...mapActions("form_configurations", ["getFormConfigurationById"]),
        async addScheduleForUser() {
            this.savingNewSchedule = true;
            try {
                const response = await this.createScheduleForUser({
                    userId: this.user.id,
                    scheduleName: this.newScheduleName,
                });

                this.showAddScheduleModal = false;
                this.newScheduleName = "";
            } catch (err) {
                console.log(err);
                window.notify.apiError(err);
            } finally {
                this.savingNewSchedule = false;
            }
        },
        updateCurrentSchedule(newScheduleId) {
            this.selectedScheduleId = null;
            this.$nextTick(() => {
                this.selectedScheduleId = newScheduleId;
            })
        }
    },
};
</script>
<template>
    <div class="text-left">
        <h3 class="mb-4 shadow-bottom">Schedules for {{ user.first_name }}&nbsp;{{ user.last_name }}</h3>

        <div class="flex">
            <div v-for="schedule in schedulesForUser"
                 class="cursor-pointer p-1 mx-1 border-dotted rounded"
                 :class="{'bg-primary text-white': schedule.id === selectedScheduleId}"
                 @click="updateCurrentSchedule(schedule.id)"
            >
                {{ schedule.name }}
            </div>
            <div
                v-if="schedulesForUser.length === 0"
                style="width: 8rem"
                class="mx-2 border-dotted rounded"
            >
                &nbsp;
            </div>
            <button class="p-1 border-2" @click="showAddScheduleModal = true">
                <i class="fa fa-plus"></i>&nbsp;Add Schedule
            </button>
        </div>

        <div v-if="schedulesForUser.length == 0" class="mt-4">
            <p>
                Please click the "add schedule" button above to add a schedule
                for usage within projects.
            </p>
        </div>

        <div v-if="userScheduleEntityType && userScheduleEntityForm && selectedScheduleId">
            <user-schedule-form
                :user-schedule="selectedUserSchedule"
            ></user-schedule-form>
        </div>
        <div v-else-if="!userScheduleEntityType && !userScheduleEntityForm">
            <div class="flex justify-center">
                <i class="fa fa-4x fa-spinner fa-spin"></i>
            </div>
        </div>
        <modal
            v-if="showAddScheduleModal"
            @close="showAddScheduleModal = false"
            :modal-width="modalWidth"
        >
            <template #header>
                <h3>Add Schedule</h3>
            </template>
            <template #body>
                <div class="py-4">
                    <form-text
                        field-name="new-schedule-name"
                        label="Schedule Name"
                        v-model="newScheduleName"
                        :required="true"
                        :find-in-form="false"
                    ></form-text>

                    <div class="flex justify-end">
                        <button
                            class="button"
                            :disabled="savingNewSchedule"
                            @click="addScheduleForUser"
                        >
                            <i
                                v-if="savingNewSchedule"
                                class="fa fa-spinner fa-spin"
                            ></i>
                            <i v-else class="fa fa-plus"></i>&nbsp;Add Schedule
                        </button>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>
