<template>
    <tr>
        <td style="width: 10%">{{record.api_method}}</td>
        <td style="width: 10%">{{record.api_url}}</td>
        <td class="w-quarter" ref="api-params"></td>
        <td class="w-quarter" ref="api-response"></td>
        <td style="width: 10%">{{created}}</td>
    </tr>
</template>
<script>
import JSONFormatter from "json-formatter-js";
import moment from "moment";
export default {

    props: {
        record: {
            required: true,
            type: Object
        }
    },

    mounted() {

        this.$nextTick(() => {
            const paramsFormatter = new JSONFormatter(this.record.api_params);
            this.$refs['api-params'].appendChild(paramsFormatter.render());

            const responseFormatter = new JSONFormatter(this.record.api_response);
            this.$refs['api-response'].appendChild(responseFormatter.render());
        })
    },

    computed: {
        created() {
            return moment(moment.utc(this.record.created_at)).local().format('MM/DD/YYYY hh:mm:ss a')
        },
    }
}
</script>
