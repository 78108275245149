<script>
import pluralize from "etap/utilities/pluralize";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default {
    props: {
        baseUrl: {
            required: true,
            type: String,
        },
        entityType: {
            required: true,
            type: String,
        },
        notable: {
            required: true,
            type: Object,
        },
        title: {
            type: String,
            default: "Note",
        },
    },

    data() {
        return {
            icons: {
                faPlus,
            },
            loading: false,
            showAddNote: false,
            refreshingNotes: false,
        }
    },

    async created() {
        this.loading = true;
        try {

            if(!this.notableEntityTypeRecord) {
                await this.$store.dispatch('entity_types/getEntityTypeForName', this.entityType);
            }

            if(!this.noteEntityTypeRecord) {
                await this.$store.dispatch('entity_types/getEntityTypeForName', 'note');
            }

            if(
                !this.noteForm &&
                this.noteEntityTypeRecord.default_form_configuration_id
            ) {
                await this.$store.dispatch(
                    'form_configurations/getFormConfigurationById',
                    this.noteEntityTypeRecord.default_form_configuration_id
                )
            }

            await this.refreshRecords();

        }catch(err) {
            window.notify.apiError(err);
        }finally{
            this.loading = false;
        }
    },

    computed: {
        pluralTitle() {
            return pluralize.plural(this.title);
        },
        notableEntityTypeRecord() {
            return this.$store.getters['entity_types/getEntityTypeByName'](this.entityType);
        },
        noteEntityTypeRecord() {
            return this.$store.getters['entity_types/getEntityTypeByName']('note');
        },
        noteForm() {
            if(!this.noteEntityTypeRecord) {
                return null;
            }
            return this.$store.getters['form_configurations/getFormConfigurationById'](
                this.noteEntityTypeRecord.default_form_configuration_id
            );
        },
        notesForEntity() {
            if(!this.notableEntityTypeRecord) {
                return [];
            }

            return this.$store.getters['notes/getNotesForNotable']({
                notable: this.notable,
                notableEntityType: this.notableEntityTypeRecord,
            })
        },
    },

    methods: {
        async refreshRecords() {
            this.refreshingNotes = true;
            try {
                await this.$store.dispatch('notes/getNotesForEntity', this.baseUrl);
            }catch(err) {
                window.notify.apiError(err);
            }finally {
                this.refreshingNotes = false;
            }
        },
        noteCreated(createdNote) {
            this.$store.commit('notes/upsertNote', createdNote);
            window.notify.success('Note created successfully');
            this.showAddNote = false;
        }
    },
}
</script>
<template>
    <div>
        <div
            v-if="
                notableEntityTypeRecord &&
                noteEntityTypeRecord &&
                noteForm &&
                !loading
            "
        >
            <div class="flex py-4">
                <button class="button" @click="showAddNote = true">
                    <font-awesome-icon :icon="icons.faPlus"></font-awesome-icon>
                    &nbsp;Add {{ title }}
                </button>
            </div>
            <v-grid
                :data="notesForEntity"
                grid-type="divRow"
                record-type="note-row"
                :pagination="{ per_page: 5 }"
                :allow-refresh="true"
                :refresh-records="refreshRecords"
                :external-loading="refreshingNotes"
                :grid-args="{
                    notableEntityTypeRecord,
                    noteEntityTypeRecord,
                    noteForm,
                    baseUrl,
                    noRecordsText: 'No ' + pluralTitle.toLowerCase() + ' found',
                    title: title,
                }"
            ></v-grid>

            <modal
                v-if="showAddNote"
                modal-width="60%"
                @close="showAddNote = false"
            >
                <template #header>
                    Add {{ title }}
                </template>
                <template #body>
                    <div>
                        <div class="flex justify-around w-full">
                            <vue-form
                                :form-data="{}"
                                :form-config="noteForm"
                                :use-json-api="true"
                                :form-submit-url="baseUrl + '/notes'"
                                :actions="[{label: 'Save', action: 'submitForm', name: 'submit'}]"
                                @created="noteCreated"
                            ></vue-form>
                        </div>
                    </div>
                </template>
            </modal>
        </div>

        <div v-else class="h-8 text-center flex justify-center">
            <div class="loading-bar w-3/4 text-white p-12 rounded-lg">
                <span class="ml-4">Loading ...</span>
            </div>
            &nbsp;
        </div>
    </div>
</template>
