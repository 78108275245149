<script>
import { guid } from "../../utilities/utils";

export default {
    props: {
        availableTime: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            localTime: this.availableTime,
            startGuid: guid(),
            stopGuid: guid(),
        }
    },

    methods: {
        removeTimeslot() {
            this.$emit('removeItem');
        }
    }
}
</script>
<template>
    <div class="flex justify-between items-end">
        <form-timepicker
            :field-name="'start-time-' + startGuid "
            v-model="localTime.start"
            label="Start Time"
            :required="false"
            time-format="hh:mm a"
            value-format="hh:mm a"
            :find-in-form="false"
        ></form-timepicker>
        <form-timepicker
            :field-name="'stop-time-' + stopGuid "
            v-model="localTime.end"
            label="End Time"
            :required="false"
            time-format="hh:mm a"
            value-format="hh:mm a"
            :find-in-form="false"
        ></form-timepicker>
        <div class="mb-4">
            <button class="remove-button" @click="removeTimeslot">
                <i class="fa fa-times"></i>&nbsp; Remove
            </button>
        </div>
    </div>
</template>
<style>
.remove-button {
    background: $gray;
    padding: 5px;
}
</style>
