<script>
import { faChevronDown, faChevronRight, faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {byString} from "laravel-vue-forms";

export default {

    props: {
        record: {
            required: true,
            type: Object
        },
        args: {
            required: true,
            type: Object
        },
    },

    data() {
        return {
            icons: {
                faChevronDown,
                faChevronRight,
                faEdit,
                faTimes,
            },
            showForm: false,
            showConfirmDelete: false,
            deleting: false,
        }
    },

    created() {

        if(this.isNew) {
            this.showForm = true;
        }

    },

    computed: {
        isNew() {
            return !this.record.id;
        },
        taskFeature() {
            return window.MAT.features.find(f => f.name === 'tasks');
        },
        taskFormConfiguration() {
            if(
                !this.taskFeature?.options?.tasks_form_configuration
            ) {
                return null;
            }

            return this.$store.getters['form_configurations/getFormConfigurationByName']
            (this.taskFeature.options.tasks_form_configuration);
        },
        taskDisplayColumns() {
            if(
                !this.taskFeature ||
                !this.taskFeature.enabled ||
                !this.taskFeature.options?.tasks_layout_view_display_columns
            ) {
                return [];
            }

            return this.taskFeature.options.tasks_layout_view_display_columns;
        },
        taskStatues() {
            const statusesModel = this.taskFeature.options?.task_statuses;
            if(!statusesModel) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](statusesModel);
        },
        taskTypes() {
            const taskTypesModels = this.taskFeature.options?.task_types;
            if(!taskTypesModels) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](taskTypesModels);
        },
        taskDisplayValues() {
            return this.taskDisplayColumns.map(col => {
                const ff = this.taskFormConfiguration.fields.find(f => {
                    return f.value_field === col;
                });

                let value = null;
                let label = null;
                switch(ff?.value_field) {
                    case 'task_status_id':
                        value = this.taskStatues.find(s => {
                            return s.id === this.record.task_status_id;
                        })?.name;
                        label = ff.label;
                        break;
                    case 'task_type_id':
                        value = this.taskTypes.find(t => {
                            return t.id === this.record.task_type_id;
                        })?.name;
                        label = ff.label;
                        break;
                    default:
                        switch(col) {
                            case 'created_at':
                                label = 'Created';
                                value = this.record.created_at;
                                break;
                            case 'updated_at':
                                label = 'Last Updated';
                                value = this.record.updated_at;
                                break;
                            default:
                                label = ff?.label;
                                value = byString(this.record, ff.value_field);
                        }
                }

                return {
                    label: label,
                    value: value
                }
            })
        },
        formSubmitUrl() {
            if(this.isNew) {
                return '/api/projects/' + this.args.projectId + '/tasks';
            }

            return '/api/projects/' + this.args.projectId + '/tasks/' + this.record.id;
        },
        formActions() {
            if(this.isNew) {
                return [
                    {
                        label: 'Create Task',
                        name: 'create',
                        action: 'submitForm',
                    }
                ]
            }

            return [
                {
                    label: 'Update Task',
                    name: 'update',
                    action: 'submitForm',
                }
            ]
        },
        taskEntityType() {
            return this.$store.getters['entity_types/getEntityTypeByName']('task');
        },
    },

    methods: {
        taskCreated(createdTask) {
            window.notify.success('Task successfully created.', 'Created');
            this.$store.commit('tasks/removeNewTaskForProject', this.args.projectId);
            this.$store.commit('tasks/upsertTask', createdTask);
        },
        taskUpdated(updatedTask) {
            this.$store.commit('tasks/upsertTask', updatedTask);
            window.notify.success('Task successfully updated.', 'Updated');
        },
        async deleteTask() {
            this.deleting = true;
            this.showConfirmDelete = false;
            try {
                await this.$store.dispatch('tasks/deleteTaskForProject', this.record);
                window.notify.success('Task successfully deleted.', 'Deleted');
            }catch(err) {
                window.notify.apiError(err);
            }finally {
                this.deleting = false;
            }
        }
    }
}
</script>
<template>
    <div>
        <div class="border-2 w-full rounded">
            <div class="flex items-center p-2 shadow-md" @click="showForm = !showForm">
                <div
                    class="cursor-pointer ml-2 md:ml-8 flex justify-between items-center w-full"
                >
                    <div class="flex justify-around">
                        <div class="mx-2">
                            <font-awesome-icon
                                v-if="showForm"
                                :icon="icons.faChevronDown"
                                size="lg"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else
                                :icon="icons.faChevronRight"
                                size="lg"
                            ></font-awesome-icon>
                        </div>
                        <span
                            v-if="isNew"
                            class="p-1 text-white text-sm bg-primary rounded"
                        >
                            New
                        </span>
                    </div>
                    <div class="w-3/4">
                        <div class="flex justify-between items-center">
                            <div class="mx-2" v-for="displayValue in taskDisplayValues">
                                <label>{{ displayValue.label }}</label>
                                <div>{{ displayValue.value }}&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="mx-2 border-0" @click.stop="showForm = true">
                            <font-awesome-icon :icon="icons.faEdit" size="lg"></font-awesome-icon>
                        </button>
                        <button class="border-0" @click.stop="showConfirmDelete = true">
                            <font-awesome-icon :icon="icons.faTimes" size="lg"></font-awesome-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div v-if="showForm" class="shadow-inner w-full p-4">
                <div>
                    <div v-if="!record.id">
                        <vue-form
                            :form-config="taskFormConfiguration"
                            :form-data="record"
                            :form-submit-url="formSubmitUrl"
                            :actions="formActions"
                            :use-json-api="true"
                            @created="taskCreated"
                            @updated="taskUpdated"
                            :disabled="deleting"
                        />
                    </div>
                    <div v-else>
                        <tabs>
                            <tab name="Task Details">
                                <vue-form
                                    :form-config="taskFormConfiguration"
                                    :form-data="record"
                                    :form-submit-url="formSubmitUrl"
                                    :actions="formActions"
                                    :use-json-api="true"
                                    @created="taskCreated"
                                    @updated="taskUpdated"
                                    :disabled="deleting"
                                />
                            </tab>
                            <tab name="Notes & Comments">
                                <notes-sub-view
                                    :base-url="'/api/tasks/' + record.id"
                                    entity-type="task"
                                    :notable="record"
                                    title="Comment"
                                />
                            </tab>
                            <tab name="Audits">
                                <audits-sub-view
                                    :auditable="record"
                                    :auditable-entity-type="taskEntityType"
                                />
                            </tab>
                        </tabs>
                    </div>
                </div>
            </div>
        </transition>
        <modal
            v-if="showConfirmDelete"
            @close="showConfirmDelete = false"
            modal-width="40%"
            :is-confirm="true"
            @confirmed="deleteTask"
        >
            <template #header>
                Are you sure you want to delete this task?
            </template>
            <template #body>
                <div class="w1/3 flex justify-center">
                    <p>
                        This will permanently delete this task. This action cannot be undone.
                    </p>
                </div>
            </template>
        </modal>
    </div>
</template>
