<script>
    import axios from 'axios';
    import {assignOnObject, byString} from "laravel-vue-forms";
    export default {
        data() {
            return {
                inspectorId: null,
                loadingInspectionData: false,
                submittingForm: false
            }
        },
        props: {
            inspectionPhase: {
                required: true,
                type: Object
            },
            projectId: {
                required: true,
                type: Number,
            },
            currentInspectorId: {
                required: false,
                type: Number,
                default: null
            },
            disabled: {
                required: false,
                default: false,
                type: Boolean
            },
            formConfiguration: {
                required: true,
                type: Object
            },
        },
        async created() {
            try {
                this.loadingInspectionData = true;
                await this.loadInspectionData();
                this.loadingInspectionData = false;
                this.inspectorId = this.currentInspectorId;
            }catch(err) {
                console.log(err);
                window.notify.error('Error loading inspection data');
            }
        },
        computed: {
            inspectors() {
                return this.$store.state.inspections.inspectors;
            },
            inspection() {
                let inspectionData = this.$store.getters['inspections/getInspectionForProjectAndPhase'](
                    this.projectId,
                    this.inspectionPhase.id
                );

                if(!inspectionData) {
                    return null;
                }

                this.inspectorId = inspectionData.project.inspector_id;
                return inspectionData;
            },
            formData() {
                let formData = {};
                this.formConfiguration.fields.forEach(field => {
                    if(field.visible) {
                        assignOnObject(
                            formData,
                            field.value_field,
                            byString(this.inspection, field.value_field)
                        )
                    }
                });
                formData.project_id = this.projectId;
                return formData
            },
            actions() {
                return [{
                    name: "submit",
                    label: "Assign Inspection",
                    action: "submitForm"
                }]
            }
        },

        methods: {
            async loadInspectionData() {
                await this.$store.dispatch('inspections/getInspectors');
                await this.$store.dispatch('inspections/getProjectInspection', {
                    project_id: this.projectId,
                    phase_id: this.inspectionPhase.id
                });
            },
            assignAndOpenInspection(inspectionFormData) {
                this.submittingForm = true;
                axios.post('/api/projects/' + this.projectId + '/inspections', {
                    data: inspectionFormData,
                    phaseId: this.inspectionPhase.id
                }).then(response => {
                    this.$store.commit('inspections/addInspection', response.data);
                    this.inspectorId = response.data.project.inspector_id;
                    this.submittingForm = false;
                    window.notify.message('Successfully assigned and created inspection', 'success');
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                }).catch(error => {
                    this.submittingForm = false;
                    window.notify.apiError(error);
                });
            },
            reAssignInspector() {
                if(this.inspectorId === null) {
                    window.notify.message('Please select an inspector to re-assign', 'error');
                } else {
                    this.submittingForm = true;
                    this.$store.dispatch('inspections/reAssignInspector', {
                    projectId: this.projectId,
                    inspectionId: this.inspection.id,
                    inspectorId: this.inspectorId,
                    }).then(response => {
                        this.submittingForm = false;
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000)
                    });
                }
            },
            rewindInspection() {
                this.submittingForm = true;
                this.$store.dispatch('inspections/rewindInspection', {
                    projectId: this.projectId,
                    inspectionId: this.inspection.id
                }).then(response => {
                    this.submittingForm = false;
                    window.notify.message('Successfully rewound inspection', 'success');
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                });
            },
            goToInspection() {
                window.location.href = '/project/' + this.projectId + '/' + this.inspectionPhase.name
            }
        }
    }
</script>
<template>
    <div>
        <div v-if="!loadingInspectionData">
            <div v-if="inspection !== null" class="vue-form text-center">
                    <h3>Manage Inspection</h3>
                    <form-select
                        field-name="inspector"
                        v-model="inspectorId"
                        label="Select an inspector to assign"
                        :show-label="false"
                        :options="inspectors"
                        option-label-field="username"
                        option-value-field="id"
                        :show-multiselect-labels="false"
                        :disabled="disabled ? 1 : 0"
                    ></form-select>
                    <div>
                        <button :disabled="submittingForm" class="button m-2" @click="reAssignInspector">
                            Re-assign Inspection
                        </button>
                        <button :disabled="submittingForm" class="button m-2" @click="rewindInspection" v-can="'rewind_inspections'">
                            Rewind Inspection
                        </button>
                        <button :disabled="submittingForm" class="button m-2" @click="goToInspection">
                            Go To Inspection
                        </button>
                        <div v-show="submittingForm">
                            <span class="fa fa-spinner fa-spin"></span>Saving
                        </div>
                    </div>
                </div>
            <div v-else class="vue-form">
                <vue-form
                    :form-config="formConfiguration"
                    :form-data="formData"
                    :auto-save="false"
                    :pass-thru="true"
                    :is-saving="submittingForm"
                    :disabled="disabled"
                    :actions="actions"
                    @updated="assignAndOpenInspection"
                ></vue-form>
            </div>
        </div>
        <div v-else class="text-center flex justify-center mb-4">&nbsp;
            <div class="loading-bar w-3/4 text-white p-12 rounded">
                <span class="ml-4">Loading ...</span>&nbsp;
            </div>
        </div>
    </div>
</template>
